import React, { useState, useEffect } from "react";
import {
  withStyles,
  Grid,
  ButtonBase,
  Tooltip
} from "@material-ui/core";
import classNames from "classnames";
import { Typography } from "../Wrappers";
// Dependencies
import {GATEWAY, MASTERS_GATEWAYS, ALERT_MESSAGE} from "@/common/constants";
import { safeJSONParse, uppercaseFirst } from "@/common/utils/helpers";

const style = theme => {
  return {
    root: {},
    gateways: {
      [theme.breakpoints.down('xs')]: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      },
    },
    mainClass: {
      height: 94,
      width: "calc(100% - 20px)",
      marginTop: "30px",
      marginBottom: "30px",
      [theme.breakpoints.down('sm')]: {
        marginTop: "20px",
        marginBottom: "20px",
      },
      "& .innerElement": {
        boxShadow: "0 0 0 1px #b4c2c9",
        paddingTop: "27px",
        "& img": {
          height: "35px",
          width: "auto",
        }
      },
      "&:active": {
        borderStyle: "outset",
      },
      "&.Mui-disabled": {
        opacity: "0.5",
      }
    },
    mainLblClass: {
      color: theme.palette.black,
      textTransform: "uppercase",
      fontSize: 20,
      letterSpacing: 1,
      marginTop: 16,
      marginBottom: 16
    },
    //class for checked item
    checked: {
      borderRadius: '6px',
      "& .innerElement": {
        boxShadow: "0 0 0 1.5px #04354b",
        position: "relative",
        "&::before": {
          position: "absolute",
          top: "-11px",
          right: "-11px",
          content: "' '",
          display: "block",
          height: "24px",
          width: "24px",
          borderRadius: "50%",
          background: "#04354b url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill:%23fff' width='18' height='18' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'%3E%3C/path%3E%3C/svg%3E\") center center no-repeat",
        }
      }
    },
    LblChecked: {color: 'grey' },
    itemInner: {
      height: 94,
      width: 229,
      borderRadius: '6px',
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      transition: theme.transitions.create("all", {
        easing: "cubic-bezier(0.29, 1.42, 0.79, 1)"
      }),
    },
    input: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: -6,
      opacity: 0,
      cursor: "pointer",
    },
    directions: {
      color: "#000",
      fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
      fontSize: "24px !important",
      lineHeight: "1.33",
      width: "376px",
      textAlign: "center",
      fontWeight: "500 !important",
      padding: "35px 0 0 0",
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px !important",
        width: "100%",
        paddingRight: "20px",
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px !important",
      },
    },
    workingOffer: {
      color: "#000",
      fontSize: "16px !important",
      fontWeight: "400 !important",
      "& span": {
        backgroundColor: "#f2f5f6",
        borderRadius: "1000px",
        padding: "5px 12px",
        display: "inline-block",
        margin: "0 0 0 10px",
        [theme.breakpoints.down("xs")]: {
          display: "block",
          margin: "10px 0 0 0",
        },
      }
    },
    workingOfferCountry: {
      color: "#000",
      fontSize: "16px !important",
      fontWeight: "400 !important",
      "& span": {
        borderBottom: '1px solid #fa4616',
        color: '#fa4616',
        padding: "0px",
        display: "inline-block",
        margin: "0 0 0 10px",
        [theme.breakpoints.down("xs")]: {
          display: "block",
          margin: "10px 0 0 0",
        },
      }
    },
    footnote: {
      color: "#666",
      fontSize: "14px",
      fontWeight: "500 !important",
      lineHeight: "1.5",
      margin: "30px 0 35px 0",
      width: "477px",
      textAlign: "center",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    }
  };
};

const ConnectAccount = ({ brand_offers, classes, country, onChecked, onCountryClicked }) => {

  let [checked, setChecked] = useState(-1);

  const handleChecked = (i, master) => e => {
    setChecked(i);
    onChecked?.(master);
  }

  const gateway = checked === 0? GATEWAY.paypal: checked === 1? GATEWAY.stripe:'';
  localStorage.setItem('gateway', JSON.stringify(gateway));

  // const [offers_selection] = useLocalStorage(LOCAL_STORAGE_KEYS.OFFER_SELECTION, []);
  const offers_selection =  safeJSONParse(localStorage.getItem('offers_selection') || [], []);

  const itemOuterClass = i =>
    classNames({
      [classes.mainClass]: true,
      [classes.checked]: checked === i
    });

  // Connection availability
  const isConnection = (gateway) => {
    const connections = ConnectionList();
    if(~connections.indexOf(gateway.toLowerCase())){
      return {
        available: true,
        selected: connections.length === 1
      };
    }else{
      return {
        available: false,
        selected: false
      };
    }
  }

  const ConnectionList = () => {
    if(Object.keys(brand_offers).length){
      let selected_offer = brand_offers[offers_selection[0]] || {};
      if(Object.keys(selected_offer).length){
        return AvailableGateways(selected_offer);
      }else{
        return AvailableGateways(null);
      }
    }else{
      return AvailableGateways(null);
    }
  }

  const AvailableGateways = (brand_offer) => {
    const connections = [];
    const gateways = MASTERS_GATEWAYS.map(a => a.name.toLowerCase());
    for (let i = 0; i < gateways.length; i++) {
      if(brand_offer !== null && (gateways[i] in brand_offer)){
        const account = brand_offer[gateways[i]];
        switch(gateways[i]){
          case 'paypal':
            try {
              let delta = 0;
              delta += (account.permissionsGranted === true || account.permissionsGranted === 'true' ? 1 : 0);
              delta += (account.isEmailConfirmed === true || account.isEmailConfirmed === 'true'  ? 1 : 0);
              delta += (account.merchant_id !== undefined && account.merchant_id !== '' ? 1 : 0);
              if(delta !== 3){
                connections.push(gateways[i]); // not connected
              }
            } catch(err) {
              // trial failed, make connection available
              connections.push(gateways[i]);
            }
            break;
          case 'stripe':
            if(!account.charges_enabled){
              connections.push(gateways[i]);
            }
            break;
          default:
            connections.push(gateways[i]);
        }
      }else{
        connections.push(gateways[i]);
      }
    }
    return connections;
  }

  useEffect(() => {
    // Update checked connection if only selection
    if (offers_selection?.[0]?.length) {
      let selectedConnection = -1;
      let totalConnections = 0;
      const gateways = MASTERS_GATEWAYS.map((a) => a.name.toLowerCase());
      for (let i = 0; i < gateways.length; i++) {
        if (isConnection(gateways[i]).selected) {
          selectedConnection = i;
        }
        if (isConnection(gateways[i]).available) {
          totalConnections++;
        }
      }
      if (totalConnections > 0) {
        if (selectedConnection !== -1) {
          setChecked(selectedConnection);
        }
      } else {
        if (checked !== -1) {
          setChecked(-1);
        }
      }
    }
  }, [offers_selection]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography className={classes.directions}>
          Select a payment gateway to connect to your offer.
        </Typography>
      </Grid>
      {MASTERS_GATEWAYS.map((master, i) => {
        return (
          <Grid key={i} className={classes.gateways}>
            <ButtonBase
              className={itemOuterClass(i)}
              disabled={!isConnection(master.name).available}
            >
              <Tooltip title={master.tooltipTxt} placement="top-end">
                <input
                  type="radio"
                  name="master"
                  id={master.name}
                  value={checked === i && master.name}
                  checked={checked === i}
                  onChange={handleChecked(i, master)}
                  className={classes.input}
                />
              </Tooltip>
              <div
                className={classNames({
                  [classes.itemInner]: true,
                  innerElement: true,
                })}
              >
                <img src={master.url} alt={master.name} />
              </div>
            </ButtonBase>
          </Grid>
        );
      })}
      <Grid
        item
        xs={12}
        sm={12}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography className={classes.workingOffer}>
          Offer to be linked:
          {offers_selection.map((offer, i) => (
            <span key={i}>{uppercaseFirst(offer)}</span>
          ))}
        </Typography>
        {country && (
          <div onClick={(country) => onCountryClicked(country)} style={{ cursor: 'pointer', margin: '12px 0' }}>
            <Typography className={classes.workingOfferCountry}>
              Click to change Country: <span>{country}</span>
            </Typography>
          </div>
        )}
        <Typography className={classes.footnote}>
          {ALERT_MESSAGE.completeSignUp}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(ConnectAccount);