import React, { useState } from "react";

import {
  AppBar,
  Toolbar,
  Link
} from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";

// icons & logos
import logo from '@/assets/images/giddyup-logomark.svg';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "@/context/LayoutContext";

import { useUserDispatch, signOut } from "@/context/UserContext";
import { clearLocalStorageData } from "@/common/utils/helpers";
import { offerActions, useOfferDispatch } from "@/context/OffersContext";

// TODO: need to implement notifications.
const notifications = [
  {
    id: 0,
    color: "success",
    type: "info",
    message: "Your account has been connected successfully ...",
  },
  { id: 1, color: "primary", type: "notification", message: "Something went wrong, please try to connect your account again" },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  const classes = useStyles();

  let email = localStorage.getItem('cfEmail')?.toLocaleUpperCase() || '';

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const offerDispatch =  useOfferDispatch();

  // local
  let [notificationsMenu, setNotificationsMenu] = useState(null);
  let [ isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  let [profileMenu, setProfileMenu] = useState(null);

  const handleSignOut = () => {
    clearLocalStorageData();
    offerDispatch(offerActions.resetInitialState());
    signOut(userDispatch, props.history);
  }

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>

        <div className={classes.logomark}>
          <img src={logo} alt='logo' />
        </div>

        <Typography variant="h6" weight="medium" className={classes.appTitle}>
          GiddyUp Payment Portal
        </Typography>

        <div className={classes.grow} />

        <Button
          component={Link}
          variant={"outlined"}
          color={"primary"}
          className={classes.headerMenuButton}
          onClick={handleSignOut}
        > Sign Out </Button>

      </Toolbar>
    </AppBar>
  );
}
