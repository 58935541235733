import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {withStyles, FormControlLabel, Grid, Radio, RadioGroup, CircularProgress} from "@material-ui/core";
import { Typography } from "../Wrappers";
import { safeJSONParse } from "@/common/utils/helpers";
import { marketplaceService } from "@/services/marketplace";
import useService from "@/hooks/useService";
import { uppercaseFirst } from "@/common/utils/helpers";
import { MASTERS_GATEWAYS } from "@/common/constants";
import { useUserState } from "@/context/UserContext";
import { useOfferState } from "@/context/OffersContext";

const style = (theme) => ({
  root: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    padding: "0 20px",
    margin: " 0 auto"
  },
  row: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    borderBottom: "1px solid #e6ebed",
    height: "70px"
  },
  selectorContainer: {
    width: "100%",
  },
  loadingContainer: {
    padding: "35px 0",
    "& span": {
      display: "inline-block",
      margin: "0 0 0 20px",
      position: "relative",
      top: "-10px"
    }
  },
  selector: {
    color: "#000",
  }
});

const offerToOfferItem = (offer) => ({ ...offer, name: offer?.offer });

const SelectOffer = ({ classes, offerSelected, brand_offers }) => {
  // state data
  const [offers, setOffers] = useState();
  const [brand, setBrand] = useState(safeJSONParse(localStorage.getItem("brand"), []));
  const [serviceSelected, setServiceSelected] = useState({});
  const [textSelected, setTextSelected] = useState('');
  const [offers_name, setOffersName] = useState();

  // hooks
  const { user } = useUserState();
  const {offers: ctxOffers } = useOfferState();
  const getAccountOffers = useService(marketplaceService.GetAccountOffers);
  const getBrand = useService(marketplaceService.GetBrand);

  // side effects / use Effects
  useEffect(() => {
    // getAccountOffers
    //   .service()
    //   .then(({ result }) => setOffers(result?.map?.(offerToOfferItem) || []))
    //   .catch(console.error);
      
    setOffers(ctxOffers?.map?.(offerToOfferItem));
    // getBrand.service(user.email).then(setBrand).catch(console.error);
  }, []);

  useEffect(() => {
    const selected = offers?.reduce?.((obj, item) => {
      obj[item.name] = false;
      return obj;
    }, {});

    setServiceSelected(selected || {});
  }, [offers]);

  useEffect(() => {
    const textSelected = Object.keys(serviceSelected || {})
    .filter((key) => serviceSelected[key])
    .join(",")

    setTextSelected(textSelected);
  }, [serviceSelected])

  useEffect(() => {
    setOffersName((textSelected || '').split(","))
  }, [textSelected]);

  useEffect(() => {
    localStorage.setItem("offers_selection", JSON.stringify(offers_name));
  }, [offers_name]);

  // event handlers
  const handleChange = (name) => (e) => {
    setServiceSelected({
      [name]: e.target.checked,
    });
    offerSelected(true); // enables next button
  };

  // Remove fully connected offers
  const ConnectionList = (offer_config) => {
    if(Object.keys(brand_offers).length){
      let selected_offer = brand_offers[offer_config] || {};
      if(Object.keys(selected_offer).length){
        return AvailableGateways(selected_offer);
      }else{
        return AvailableGateways(null);
      }
    }else{
      return AvailableGateways(null);
    }
  }

  const AvailableGateways = (brand_offer) => {
    const connections = [];
    const gateways = MASTERS_GATEWAYS.map(a => a.name.toLowerCase());
    for (let i = 0; i < gateways.length; i++) {
      if(brand_offer !== null && (gateways[i] in brand_offer)){
        const account = brand_offer[gateways[i]];
        switch(gateways[i]){
          case 'paypal':
            try {
              let delta = 0;
              delta += (account.permissionsGranted === true || account.permissionsGranted === 'true' ? 1 : 0);
              delta += (account.isEmailConfirmed === true || account.isEmailConfirmed === 'true'  ? 1 : 0);
              delta += (account.merchant_id !== undefined && account.merchant_id !== '' ? 1 : 0);
              if(delta !== 3){
                connections.push(gateways[i]); // not connected
              }
            } catch(err) {
              // trial failed, make connection available
              connections.push(gateways[i]);
            }
            break;
          case 'stripe':
            if(!account.charges_enabled){
              connections.push(gateways[i]);
            }
            break;
          default:
            connections.push(gateways[i]);
        }
      }else{
        connections.push(gateways[i]);
      }
    }
    return connections;
  }

  return (
    <Grid
      item
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      {!offers?.length && (
        <div
          className={classes.loadingContainer}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "120px",
          }}
        >
          <CircularProgress size={30} />
          <div style={{ margin: '0 10px'}}>
            <Typography>Loading Offers...</Typography>
          </div>
        </div>
      )}
      {offers?.length && (
        <RadioGroup className={classes.selectorContainer}>
          {offers?.map?.((offer, i) => {
            if (ConnectionList(offer.name).length !== 0) {
              return (
                <Grid
                  id="selection"
                  item
                  key={i}
                  container
                  justifyContent="space-between"
                  className={classes.row}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={handleChange(offer.name)}
                        value={offer.name}
                        color="primary"
                      />
                    }
                    className={classes.selector}
                    label={uppercaseFirst(offer.name)}
                    //checked={serviceSelected[service.name]}
                    labelPlacement="end"
                  />
                </Grid>
              );
            }
          })}
        </RadioGroup>
      )}
      <input
        type="text"
        value={textSelected}
        name="selected services"
        style={{ width: 1, height: 1, opacity: 0.1 }}
        readOnly
      />
    </Grid>
  );
};

SelectOffer.propTypes = {
  classes: PropTypes.object,
  offerSelected: PropTypes.func,
};

export default withStyles(style)(SelectOffer);
