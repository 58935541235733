import axios from 'axios';
import axiosRetry from 'axios-retry';
import { authService } from '../auth';

/**
 * This is what we want a service response to conform.
 *
 * @param {object} [res={}] - a generic response object. should have header, data, status.
 * @return {*}
 */
const httpResponse = (res = {}) => {
    return {
        headers: res.header || {}, // HTTP headers that the server responded with
        data: res.data || {}, // response that was provided by the server
        status: res.status || 0 // HTTP status code from the server response
    };
};

const retryConfig = {
    retryDelay: axiosRetry.exponentialDelay,
    retries: 3
};

/**
 * Service based on the axios library
 * Axios is a Promise based HTTP client for the browser and node.js
 * For reference out {@link https://www.npmjs.com/package/axios}
 *
 * @param {object} [configs={}] - axios configs such auth, headers, transforms.
 * @return {object<axios>} - axios instance
 */
const axiosBasedHttpService = (configs = {}) => {
    const axiosService = axios.create(configs);

    axiosService.interceptors.request.use(async (request) => {
      try {
        const user = await authService.getSessionUser();
        if (user && !request.headers.Authorization) {
          request.headers.Authorization = `Bearer ${user.accessToken}`;
        }
      } catch (error) {
        console.log("error in setting interceptor");
      }

      return request;
    });

    // On the occasion of server error 500 and other known errors such user's network is down,
    // we will try exponential back-off retry delay between requests
    axiosRetry(axiosService, retryConfig);

    // This is specific to axios lib error
    const handleAxiosError = (error) => {
        if (!error.response && !error.request) throw error; // Something happened in setting up the request that triggered an Error
        return error.response
            ? httpResponse(error.response)
            : httpResponse({ status: 500, data: { error: error.request } });
    };

    return {
        get: (url, configuration = {}) => axiosService.get(url, configuration).then(httpResponse, handleAxiosError),
        post: (url, data, configuration = {}) => axiosService.post(url, data, configuration).then(httpResponse, handleAxiosError),
        delete: (url, configuration = {}) => axiosService.delete(url, configuration).then(httpResponse, handleAxiosError),
        put: (url, data, configuration = {}) => axiosService.put(url, data, configuration).then(httpResponse, handleAxiosError),
        patch: (url, data, configuration = {}) => axiosService.patch(url, data, configuration).then(httpResponse, handleAxiosError),
        setBaseUrl: (url) => !!(axiosService.defaults.baseURL = url),
        setHeaders: (headers) => {
            const commonHeaders = axiosService.defaults.headers.common || {};
            axiosService.defaults.headers.common = Object.assign(commonHeaders, headers);
        }
    };
};

/**
 * This is the common interface we want to use through out our code base.
 * url is the endpoint, if baseUrl is set then it will request from concatenation of baseUrl and url.
 * configs is where you pass things such as request headers, timeouts, etc.
 * data is the body that will be sent to put or post.
 *
 * @param {object} service - Service is an object that has http methods get, put, post, delete, patch methods.
 * @return {object} - returns an object that exposes get, post, delete, patch, put.
 */
const httpClient = (service) => {
    return {
        get: async (url, configs) => service.get(url, configs),
        post: async (url, data, configs) => service.post(url, data, configs),
        delete: async (url, configs) => service.delete(url, configs),
        put: async (url, data, configs) => service.put(url, data, configs),
        patch: async (url, data, configs) => service.patch(url, data, configs),
        setBaseUrl(url) {
            service.setBaseUrl(url);
            return this; // builder pattern
        },
        setRetryConfig(config = {}) {
            retryConfig.retries = config.retries || retryConfig.retries;
            return this;
        },
        setHeaders(headers) {
            service.setHeaders(headers);
            return this;
        }
    };
};

const client = (configs = {}) => httpClient(axiosBasedHttpService(configs));

export default client;
