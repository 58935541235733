import React from "react";
import { Grid, withStyles, ButtonBase } from "@material-ui/core";

const style = theme => ({
  root: {
    overflow: "hidden",
    position: "relative",
    padding: 0,
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    fontWeight: 500,
    height: 80,
    width: "calc(100% + 16px)",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      height: 60
    }
  },
  tab: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    borderBottom: "2px solid #E6EBED",
    cursor: "pointer",
    overflow: "visible",
    width: "100%",
    color: "#000",
    height: 80,
    padding: 8,
    fontSize: 17,
    [theme.breakpoints.down("xs")]: {
      height: 60,
      fontSize: 10
    },
    "& .counter": {
      height: "30px",
      width: "30px",
      lineHeight: "30px",
      fontWeight: "500",
      borderRadius: "50%",
      textAlign: "center",
      display: "inline-block",
      backgroundColor: "#b4c2c9",
      color: "#fff",
      marginRight:  "10px",
      position: "relative",
      "& svg": {
        fill: "#fff",
        height: 18,
        width: 18,
        position: "absolute",
        top: 6,
        left: 6,
      }
    }
  },
  indicator: {
    height: 80,
    backgroundColor: "#fff",
    position: "absolute",
    top: 0,
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0",
    color: "#000",
    borderBottom: "4px solid #f03d27",
    fontSize: 17,
    fontWeight: "normal",
    transition: theme.transitions.create("all", {
      easing: "cubic-bezier(0.29, 1.42, 0.79, 1)"
    }),
    [theme.breakpoints.down("xs")]: {
      height: 60,
      fontSize: 10
    },
    "& .counter": {
      height: "30px",
      width: "30px",
      lineHeight: "30px",
      fontWeight: "500",
      borderRadius: "50%",
      textAlign: "center",
      display: "inline-block",
      backgroundColor: "#04354b",
      color: "#fff",
      marginRight:  "10px",
      position: "relative",
    }
  }
});
const WizardHeader = ({ classes, activeStep, handleChange, tabs }) => {
  const tabWidth = 100 / tabs.length;
  const indicatorLeft = activeStep * tabWidth;
  const indicatorStyle = {
    cursor: "pointer",
    width: `${tabWidth}%`,
    left: `${indicatorLeft}%`
  };

  return (
    <Grid container className={classes.root}>
      {tabs.map((tab, index) => (
        <Grid item key={index} style={{ width: `${tabWidth}%` }}>
          <ButtonBase className={classes.tab}>
            <span className={"counter"}>
              {activeStep > index
                ? <svg viewBox="0 0 24 24" width="24" height="24"><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>
                : index+1
              }
            </span> {tab}
          </ButtonBase>
        </Grid>
      ))}
      <div style={indicatorStyle}  className={classes.indicator}>
        <span className={"counter"}>{activeStep+1}</span> {tabs[activeStep]}
      </div>
    </Grid>
  );
};
export default withStyles(style)(WizardHeader);
