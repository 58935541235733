import React, { useEffect, useState } from 'react';

// styles
import useStyles from './styles';

// components
import {
    Box,
    OutlinedInput,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    Typography
} from '@material-ui/core';
import { Button } from '@/components/Wrappers';
import { StripeCountries } from "@/components/CountryDropDown/stripe_country_codes";

export default function CountryDropDown({ value, open, onChange, onCancel, onSave }) {
  const classes = useStyles();
  const [_value, _setValue] = useState(-1);

  useEffect(() => {
    const index = StripeCountries.findIndex(country => country.code === value?.code && value.country === country.country);
    _setValue(index);
  }, [value]);

  return (
      <div>
        <Dialog disableEscapeKeyDown open={open}>
          <DialogTitle>Select Country </DialogTitle>
          <DialogContent>
            <Typography className={classes.instructions}>
              Select the country where your business is registered and your bank account will receive payments.
            </Typography>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl className={classes.formControl}>
                    <Select
                        native
                        value={_value}
                        onChange={e => onChange(StripeCountries[e.target.value])}
                        variant={"outlined"}
                        inputProps={{ "data-testid": "listbox-country-select" }}
                    >
                        <option value={-1} data-testid="country-select-option"> - Select Country - </option>
                        {StripeCountries.map((country, index) => (
                            <option value={index} key={country.code} data-testid="country-select-option">{country.country}</option>
                        ))}
                    </Select>
                </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} className={classes.cancelBtn}>Cancel</Button>
            <Button onClick={onSave} className={classes.saveBtn}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}