import passwordGenerator from 'generate-password';
import { format } from 'date-fns';
import md5 from 'md5';
import _mergeWith from  'lodash.mergewith';

export function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function mobileAndTabletCheck() {
  let check = false;
  // eslint-disable-next-line max-len
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) {
      check = true;
    }
  }(navigator.userAgent || navigator.vendor || window.opera));
  return check;
}

/**
 * Build query url based on passed parameters
 * @param {object} parameters Specify the params to build query url
 */
export function buildUrlQuery(parameters) {
  let queryUrl = '';
  let qs = '';

  /* eslint-disable guard-for-in */
  for (let key in parameters) {
    let value = parameters[key];

    if (value !== undefined) {
      qs += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
  }
  /* eslint-enable */
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); // chop off last "&"
    queryUrl = `${queryUrl}?${qs}`;
  }

  return queryUrl;
}

/**
 * Create uuid
 */
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${
    s4()}-${s4()}${s4()}${s4()}`;
}


/**
 *
 * Delay input handler
 *
 */
export const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
}());

/**
 * Render email to following format	Emi*****@xxx.com
 * @param {String} email The email need format
 */
export function renderEmail(email) {
  if (!email) {
    return undefined;
  }

  let arrs = email.split('@');
  let hash = arrs[0].substring(arrs[0].length - 6, arrs[0].length - 2);

  return email.replace(hash, '**');
}

/**
 * Return a javascript object containing the URL parameters
 * Read a page's GET URL variables and return them as an associative array.
 */
export function getUrlVars() {
  let vars = {};
  let hash;
  let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars[hash[0]] = hash[1];
  }

  return vars;
}

export function updateLocalStorage(key, value, type = 'string') {
  if (type === 'array') {
    const list = JSON.parse(localStorage.getItem(key)) || [];
    if (!list.includes(value)) {
      list.push(value);
      localStorage.setItem(key, JSON.stringify(list));
    }
  } else {
    localStorage.setItem(key, value);
  }
}

export function clearLocalStorageData() {
  window.localStorage.clear();
}

export function UrlParams(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}
  hashes.forEach(hash => {
    let [key, val] = hash.split('=')
    key = decodeURIComponent(key)
    if (key.indexOf('[') > -1) {
      // handle multiple type inputs
      if (typeof params[key] === 'undefined') {
        params[key] = []
      }

      params[key].push(decodeURIComponent(val))
    } else {
      params[key] = decodeURIComponent(val)
    }
  })
  return params
}

export function DetectQueryString() {
  const currentQueryString = window.location.search;
  return !!currentQueryString;
}

export function Validate(values) {
  let offerSelection = JSON.parse(localStorage.getItem('offers_selection'));

  offerSelection = offerSelection.filter(function(item) {
    return item === ""
  })

  let offerValidation =  offerSelection.length === 0;
  let next = document.getElementById('next');

  if ( offerValidation === false ) {
    next.style.display = 'block';
  }

  //
  // const errors = {};
  // if (!values.account_name) {
  //   errors.account_name = 'Required';
  // }

  // return errors;
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const Submit = async (values) => {
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

export function sameOfferData (obj1, obj2) {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if(obj1Length === obj2Length) {
    return Object.keys(obj1).every(
        key => obj2.hasOwnProperty(key)
            && obj2[key] === obj1[key]);
  }
  return false;
}

export const generateStrongPassword = (length) => {
  const passwordOptions = {
    length: Math.max(8, Number(length) || 0),
    numbers: true,
    lowercase: true,
    uppercase: true,
    symbols: "^$*.[]{}()?-“!@#%&/,><’:;|_~`",
  };

  let randomPassword = passwordGenerator.generate(passwordOptions);

  const randomNumber = (min, max) => Math.floor(Math.random() * (max - min) + min);

  // sometimes passwordGenerator.generate fails to generate a number
  if (!/(?=.*[\d])/.test(randomPassword)) {
    randomPassword = randomPassword.substr(0,1) + `${randomNumber(0, 9)}` + randomPassword.substr(3);
  }

  return randomPassword;
};

const detectPayPalConnectRedirect = location => {
  const { search } = location;
  return search?.service === 'paypal_connect';
}

export const timestampTxt = () => `${format(new Date(), 'MM/dd/yyyy - hh:mm:ss a')}`;

export const utcTimestampTxt = () => {
  return new Date().toISOString().replace('Z', '+00:00');
}

export const safeJSONParse = (json, defaultOnFailure) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return defaultOnFailure || null;
  }
};

/**
 * Return a string in sentence case
 */
export const uppercaseFirst = (str) => {
  if(str !== undefined && (typeof str === 'string' || str instanceof String)){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }else{
    return false;
  }
}

export const md5Hash = (plaintext) => md5(plaintext);

export function isObject(val) {
  return !!(val && Object.prototype.toString.call(val) === '[object Object]');
}

/**
 * Deep, nested, object merge that returns new object.
 * @param ...sources - source objects
 * @returns {Object}
 */
export function mergeDeep(...sources) {
  return _mergeWith({}, ...sources, (a, b) =>
    isObject(a) && isObject(b) ? mergeDeep(a, b) : b || a
  );
}

const helpers = {
  getUrlVars,
  guid,
  UrlParams,
  buildUrlQuery,
  DetectQueryString,
  Validate,
  mobileAndTabletCheck,
  renderEmail,
  Submit,
  sameOfferData,
  updateLocalStorage,
  clearLocalStorageData,
  detectPayPalConnectRedirect,
  timestampTxt,
  safeJSONParse
};

export default helpers;