import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  appBar: {
    backgroundColor: "#01344c",
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    height: "73px",
  },
  logomark: {
    "& img": {
      width: "28px",
      height: "28px",
      position: "relative",
      top: "3px",
    },
  },
  appTitle: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    color: "#fff",
    fontWeight: "500",
    fontSize: "24px !important",
    padding: "0 15px !important",
    whiteSpace: "nowrap",
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: "1",
  },
  headerMenuButton: {
    fontSize: "13px",
    color: "#fff !important",
    borderColor: "#fff !important",
  }
}));
