import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

// components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

// custom component
import LoadingButton from '@/components/Buttons/LoadingButton';

// icons & logos
import { VisibilityOff, Visibility } from '@material-ui/icons';
import logo from '@/assets/images/giddyup-logotype.svg';

// styles
import useStyles from './styles';

// context
import { userActions, useUserDispatch } from '@/context/UserContext';

// hooks
import useService from '@/hooks/useService';

// services
import { authService } from '@/services/auth';

// utils
import { UrlParams } from '@/common/utils/helpers';

function Login(props) {
  const classes = useStyles();
  const [disable, setDisabled] = useState(true);
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
  });

  useEffect(() => {
    const { username, password } = values;
    const isDisabled = !username || !password;
    setDisabled(isDisabled);
  }, [values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signIn = useService(authService.signIn, r => r.success);
  const userDispatch = useUserDispatch();

  const login = async (username, password) => {
    const response = await signIn.service(username, password);
    if (response.success) {
      const currentUser = await authService.getSessionUser();
      userDispatch(userActions.loginSuccess({ user: currentUser.user }));
      let next = UrlParams(props.location.search)?.next;
      next = next === '/' ? '/app': next;
      props.history.push(next || '/app');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!disable) login();
  };

  return (
    <Grid container className={classes.container}>

      <div className={classes.brandingPanel}>
        <div className={classes.logotype}>
          <img src={logo} alt="logo" />
        </div>
        <Typography className={classes.salutation}>
          Welcome to <span style={{whiteSpace: "nowrap"}}>the GiddyUp</span> Payment Portal
        </Typography>
      </div>

      <div className={classes.loginPanel}>
        <div className={classes.form}>
          <Box
            onSubmit={handleSubmit}
            component="form"
            id="login-form"
            noValidate
            autoComplete="off"
          >
            <div>
              <Typography variant='h2' className={classes.loginHeading}>
                Sign In
              </Typography>

              <div className={"formRow"}>
                <label>
                  Email
                  <TextField
                    id="email"
                    value={values.username}
                    onChange={handleChange('username')}
                    className={classes.loginField}
                    placeholder='Email Address'
                    type='email'
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </label>
              </div>
              <div className={"formRow"}>
                <Button
                  color='primary'
                  size='large'
                  className={classes.forgotButton}
                  onClick={() => props.history.push('/forgot-password')}
                >
                  Forgot Password
                </Button>
                <label>
                  Password
                  <FormControl variant='standard' fullWidth>
                  <Input
                    placeholder='Password'
                    id='standard-adornment-password'
                    fullWidth
                    className={classes.passwordField}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    disableUnderline={true}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          className={"inputIcon"}
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  </FormControl>
                </label>
              </div>
              <div className={"formRow"}>
                <Fade in={signIn.error}>
                  <Typography color='secondary' className={"errorMessage"}>
                    { signIn.result?.error?.message }
                  </Typography>
                </Fade>
                <div className={classes.formButtons}>
                  <LoadingButton
                    className={classes.signinButton}
                    loading={signIn.loading}
                    disabled={disable || signIn.loading}
                    onClick={() => login(values.username, values.password)}
                    variant='contained'
                    color='primary'
                    size='large'
                    type="submit"
                    htmlFor="login-form"
                  >
                    Sign In
                  </LoadingButton>
                </div>
              </div>
            </div>
          </Box>
        </div>
        <div className={classes.formFooter}>
          {/* <Typography className={classes.policies}>
            By logging in you agree to our {' '}
            <a
              href='https://giddyup.io/privacy-policy'
              rel='noopener noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>
            {' '} and {' '}
            <a
              href='https://giddyup.io/terms-of-service'
              rel='noopener noreferrer'
              target='_blank'
            >
              Terms of Service
            </a>.
          </Typography> */}
          <Typography className={classes.copyright}>
            © {new Date().getFullYear()}{' '}
            <a
              style={{ textDecoration: 'none', color: 'inherit' }}
              href='https://giddyup.io'
              rel='noopener noreferrer'
              target='_blank'
            >
              GiddyUp Group
            </a>
            . All rights reserved.
          </Typography>
        </div>
      </div>





    </Grid>
  );
}

export default withRouter(Login);
