import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  addContainer: {
    maxWidth: "600px",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "none",
    },
  },
  actions: {
    marginTop: theme.spacing(4),
    flexDirection: "row",
    '& .MuiButtonBase-root': {
      height: '48px',
    },
  },
}));
