import Dialog from "@material-ui/core/Dialog/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(0,0,0,0.0777)',
    '& .MuiBackdrop-root': {
      background: 'transparent',
    },
    '& .MuiPaper-root': {
      background: 'transparent',
      boxShadow: 'none',
      zIndex: 5
    },
    text: {
      color: "#111" 
    }
  },
}));

function FullScreenLoader({ open = false, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open}  className={classes.root}>
        <CircularProgress size={60} open={true} onClose={() => onClose?.()} style={{ background: 'transparent'}}/>
        <h2 className={useStyles.text}> Loading... </h2>
    </Dialog>
  );
}

export default FullScreenLoader;
