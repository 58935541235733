// Load config info
const env = process.env.REACT_APP_ENV || 'prod';
let config;

// Loading configuration file for specific environment
try {
    config = require(`./env/${env.trim()}`);
} catch (err) {
    process.exit(1);
}

// global configs that applies to all.
config.REACT_APP_ORIGIN = window.location.origin;

module.exports = config;
