import React, {useState} from 'react';

// styles
import useStyles from './styles';

// components
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Link,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Tooltip
} from '@material-ui/core';
import { Button, Typography } from '@/components/Wrappers';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import LoadingButton from '@/components/Buttons/LoadingButton';

// constants
import { MASTERS_GATEWAYS, ALERT_MESSAGE, GATEWAY, PAYPAL_URL, STRIPE_URL } from '@/common/constants';

// services
import { marketplaceService } from '@/services/marketplace';

// utils
import { guid, uppercaseFirst } from '@/common/utils/helpers';
import { useUserState } from '@/context/UserContext';
import { GatewayPaymentAccountValidator } from '@/common/utils/PaymentAccountValidator';

// context
import { useOfferState } from '@/context/OffersContext';

const paymentAccountValidator = new GatewayPaymentAccountValidator();

export default function OfferPaymentDetails(props) {
  const session = useUserState();
  const [offerSelected, setOfferSelected] = useState('');
  const [gatewaySelected, setGatewaySelected] = useState('');
  const [dialogReferenceAccount, setDialogReferenceAccount] = useState();
  const [isCreatingPaypalLink, setIsCreatingPaypalLink] = useState(false);
  const { offers } = useOfferState();

  const updatePayPalAccountInPaypal = async () => {
    try {
      if (isCreatingPaypalLink) return;
      setIsCreatingPaypalLink(true);
      const paypalAccount = dialogReferenceAccount;
      const paymentOffer = offers?.find(offer => offer.offer === offerSelected);
      const referenceId = paypalAccount.gu_paypal_reference_id || guid();
      const { user } = session;
      const returnUrl = `${PAYPAL_URL.return_url}&gu_offer=${paymentOffer.offer}&gu_paypal_reference_id=${referenceId}&service=paypal_connect`;
      const onboardingLink = await marketplaceService.PayPalConnectUrl(user.gu_brand_id, returnUrl);

      if (!onboardingLink.success) {
        throw new Error(onboardingLink.error?.message || 'Failed creating onboarding link');
      }

      return window.open(onboardingLink.result, '_self');
    } catch (error) {
      console.log('Error in createPaypalUrlLink()', error);
    }

    setIsCreatingPaypalLink(false);
  }

  const classes = useStyles();

  // Paypal Disconnect/Update Action
  const [open, setOpen] = useState(false);
  const closeUpdateAccount = () => {
    setOpen(false);
    setDialogReferenceAccount(null);
  };

  // Status icons
  const ConnectionStatus = (brand_offer, gateway) => {
    const account = brand_offer[gateway.toLowerCase()];
    return paymentAccountValidator.isConnected(gateway.toLowerCase(), account);
  }
  const ActionStatus = (brand_offer, gateway) => {
    const account = brand_offer[gateway.toLowerCase()];
    return paymentAccountValidator.isActionNeeded(gateway.toLowerCase(), account);
  }

  const OfferStatus = (brand_offer) => {
    return Object.values(GATEWAY).every(gateway => ConnectionStatus(brand_offer, gateway));
  }

  const ActionNeededStatus = (brand_offer) => {
    return Object.values(GATEWAY).some((gateway) => {
      return ActionStatus(brand_offer, gateway);
    });
  };

  // Format timestamp
  const formatDate = (timestamp) => {
    const dt = new Date(timestamp);
    return dt.toLocaleDateString() + ', ' + dt.toLocaleTimeString();
  }

  // Account Links
  const AccountLink = async (service, offer, account = {}, type = 'dashboard') => {
    const accountId = account?.id || account?.merchant_id;

    switch (service.toLowerCase()) {
      case 'paypal':
        if (type === 'dashboard') {
          window.open(PAYPAL_URL.dashboard);
        } else {
          setOfferSelected(offer);
          setGatewaySelected(service.toLowerCase());
          setDialogReferenceAccount(account);
          setOpen(true);
        }
        break;
      case 'stripe':
        const stripeLinkResult = await marketplaceService.StripeLoginLinkUrl(accountId);

        if (stripeLinkResult.success) {
          if (type === 'dashboard') {
            window.open(stripeLinkResult.result?.data?.link?.url);
          } else {
            setOfferSelected(offer);
            setGatewaySelected(service.toLowerCase());
            window.open(stripeLinkResult.result?.data?.link?.url + '#/settings');
          }
        } else if (accountId) {
          const returnUrl = `${STRIPE_URL.return_url}&gu_offer=${offer}`;
          const refreshUrl = `${STRIPE_URL.refresh_url}&gu_offer=${offer}`;
          const stripeOnboardingLink = await marketplaceService.StripeOnBoardingConnectUrl(accountId, returnUrl, refreshUrl);

          if (!stripeOnboardingLink.success) {
            return console.error(stripeOnboardingLink?.error?.message || 'Fail creating stripe onboarding link');
          }

          return window.open(stripeOnboardingLink.result, '_self');
        } else {
          alert(ALERT_MESSAGE.stripeLink);
          return console.error(ALERT_MESSAGE.stripeLink, '\r\n', stripeLinkResult.error);
        }
        break;
      default:
        console.error('Account link not configured');
    }
  };

  return (
    <Grid
      className={classes.cardContainer}
      container
      spacing={3}
    >
      {Object.keys(props.brand_offers)?.map?.((offer, i) => {
        const brand_offer = props.brand_offers[offer];
        return (
          <Grid id={brand_offer.name} key={i} item xs={12} sm={6}  md={4}  >
            <Card className={classes.root}>
              <CardContent className={classes.cardHeader}>
                  <Typography className={'offerName'}>
                      {uppercaseFirst(offer)}
                  </Typography>
                  {OfferStatus(brand_offer) && !ActionNeededStatus(brand_offer) && (
                    <Tooltip title={`All payment gateways fully connected`}>
                      <CheckIcon className={'checkIcon'} key={i} />
                    </Tooltip>
                  )}
              </CardContent>
              <div className={classes.cardBody}>
                {MASTERS_GATEWAYS.map((gateway, i) => {
                  const account = brand_offer[gateway.name.toLowerCase()];

                  return(<Accordion key={i} className={classes.accordion}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon style={{fill: '#000'}}/>}
                              aria-controls={`${gateway.name}-content`}
                              className={'serviceHeader'}
                          >
                            <div className={'serviceIcon'}>
                              <img src={gateway.url} alt={gateway.name}/>
                            </div>
                            <div className={'serviceActions'}>
                              {ConnectionStatus(brand_offer, gateway.name)
                                ?
                                  <>
                                    {!paymentAccountValidator.isActionNeeded(gateway.name.toLowerCase(), account)
                                        ?
                                        <Button
                                            component={Link}
                                            variant={'outlined'}
                                            color={'primary'}
                                            size={'small'}
                                            className={'dashboardButton'}
                                            onClick={(e) => {
                                              AccountLink(gateway.name, offer, account,'dashboard');
                                              e.stopPropagation();
                                            }}
                                        > Launch Dashboard
                                        </Button>
                                        :
                                        <div className={'serviceAlert'}>
                                          <Tooltip title={`Collecting funds but need additional actions inside ${gateway.name}`}>
                                            <ErrorIcon className={'alertIcon'}/>
                                          </Tooltip>
                                        </div>
                                    }
                                  </>
                                :
                                <div className={'serviceAlert'}>
                                  <Tooltip title={`${gateway.name} not connected`}>
                                    <ErrorIcon className={'alertIcon'}/>
                                  </Tooltip>
                                </div>
                              }
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className={'serviceDetails'}>
                            {ConnectionStatus(brand_offer, gateway.name)
                                ?
                                <div>
                                  <ul>
                                    <li>
                                      <span>Account ID:</span>
                                      {account.merchant_id || account.id}
                                    </li>
                                    <li>
                                      <span>Last Updated:</span>
                                      {formatDate(account.updated)}
                                    </li>
                                    <li>
                                      <Link
                                          onClick={() => AccountLink(gateway.name, offer, account, 'account')}
                                          component="button"
                                      >
                                        Update {gateway.name} Account
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                :
                                <div>
                                  This offer is not currently connected to
                                  a {gateway.name} account
                                  <br/>
                                  <strong>Use form below to connect account</strong>
                                </div>
                            }
                          </AccordionDetails>
                        </Accordion>
                  );
                })}
              </div>
            </Card>
            <Dialog key={i} open={open} onClose={closeUpdateAccount} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
                Are you sure you want to make changes
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    className={classes.dialogBody}
                >
                  {ALERT_MESSAGE.doubleCheck}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeUpdateAccount} color="tertiary" disabled={isCreatingPaypalLink}>
                  Cancel
                </Button>
                <LoadingButton autoFocus color="primary" loading={isCreatingPaypalLink}
                        onClick={() => updatePayPalAccountInPaypal()}
                >
                  Yes, I'm sure
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Grid>
        )
      })}

    </Grid>
  );
}
