import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Select,
  Slide,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';

// Components
import PageTitle from '@/components/PageTitle';

// Dependencies
import { marketplaceService } from '@/services/marketplace';
import { BRAND_STATUS } from '@/common/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@/components/Wrappers';
import useStyles from './styles';
import Widget from '@/components/Widget';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import { GatewayPaymentAccountValidator } from '@/common/utils/PaymentAccountValidator';

// custom hook
import { useLocalStorage } from '@/hooks/useLocalStorage';

// constants
import { LOCAL_STORAGE_KEYS, MASTERS_GATEWAYS } from '@/common/constants';
import {safeJSONParse, uppercaseFirst} from '@/common/utils/helpers';
import classNames from "classnames";
import useService from "@/hooks/useService";
import {offerActions} from "@/context/OffersContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ffffff',
    color: "#000000",
    borderBottom: "4px solid #e6ebed",
    paddingTop: "28px",
    paddingBottom: "28px",
  },
  body: {
    fontSize: 14,
    // backgroundColor: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#f44e1a',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant='determinate'
        className={classes.bottom}
        size={100}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={100}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const paymentAccountValidator = new GatewayPaymentAccountValidator();

function SelectAccounts() {

  const [accounts, setAccount] = useLocalStorage(LOCAL_STORAGE_KEYS.ALL_ACCOUNTS, []);
  const [visibleAccounts, setVisibleAccounts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!accounts.length) {
      marketplaceService.GetAllAccounts().then((res) => setAccount(res.result));
    }
  }, []);

  useEffect(() => {

    const accountsSorted = safeJSONParse(JSON.stringify(accounts), [])
      .filter((account) => account.brand_name !== 'default.myshopify.com')
      .sort((a, b) => a.brand_name < b.brand_name ? -1 : a.brand_name > b.brand_name ? 1 : 0);

    setVisibleAccounts(accountsSorted);

  }, [accounts]);

  const onChange = (event) => {
    localStorage.setItem('manage_account', event.target.value);
  };

  const manageAccount = async (status) => {
    let account = localStorage.getItem('manage_account');

    if (typeof account === 'string' || account instanceof String) {
      account = (visibleAccounts || []).filter(({ brand_name }) => brand_name === account);
      account[0].status = status;

      let payments =
        account[0].accountHolderDetails.payments.paypal.offers ||
        account[0].accountHolderDetails.payments.paypal.payment_offers;

      let payment_instruction = account[0].payment_instruction.paypal.offers;

      if (status === BRAND_STATUS.canceled || status === BRAND_STATUS.suspended) {
        payment_instruction = null;
      }

      if (status === BRAND_STATUS.active) {
        payment_instruction = payments;
      }

      // await marketplaceService.UpdateBrand(
      //   account[0].marketplace_gateway,
      //   account[0].brand_name,
      //   account[0].email,
      //   payment_instruction,
      //   account[0].gu_brand_id,
      //   null,
      //   null,
      //   payments,
      //   status
      // );
    } else if (!account) {
      let clickSnackbar = document.getElementById('click-snackbar-error');
      if (clickSnackbar) {
        clickSnackbar.click();
      }
    }
  };

  // Success SnackBar
  function TransitionRight(props) {
    return <Slide {...props} direction='left' />;
  }

  function TransitionTop(props) {
    return <Slide {...props} direction='top' />;
  }

  const [openConnect, setOpenConnect] = useState(false);
  const [transition, setTransition] = useState(undefined);

  const handleClickConnect = (Transition) => () => {
    setTransition(() => Transition);
    setOpenConnect(true);
  };

  const handleConnect = () => {
    setOpenConnect(false);
  };

  // Error SnackBar
  const [openConnectError, setOpenConnectError] = useState(false);
  const [transitionError, setTransitionError] = useState(undefined);

  const handleClickConnectError = (Transition) => () => {
    setTransitionError(() => Transition);
    setOpenConnectError(true);
  };

  const handleConnectError = () => {
    setOpenConnectError(false);
  };

  const OfferList = (account) => {
    if(account.payment_instruction){
      const offerNames = [];
      for (const [key, value] of Object.entries(account.payment_instruction)) {
        const offerArray = Array.isArray(value.offers) ? value.offers : [];
        const offerNamesByGateway = offerArray.map(a => uppercaseFirst(a.offer));
        offerNames.push(...offerNamesByGateway);
      }
      return [...new Set(offerNames)];
    }else{
      return [];
    }
  }

  const ConnectionStatus = (offer, service, account) => {
    const Content = [];
    const gateway = service.toLowerCase();
    if(account.payment_instruction[gateway]){
      const offerArray = Array.isArray(account.payment_instruction[gateway].offers) ? account.payment_instruction[gateway].offers : [];
      offerArray.forEach((thisOffer, index) => {
        let isConnected = false;
        let isActionNeeded = false;
        if(offer.toLowerCase() === thisOffer.offer){
          isConnected = paymentAccountValidator.isConnected(gateway, thisOffer);
          if(isConnected && gateway === 'stripe'){
            isActionNeeded = paymentAccountValidator.isActionNeeded(gateway, thisOffer);
          }
        }
        if(isConnected){
          if(isActionNeeded){
            Content.push(
              <Tooltip key={index} title={`Collecting funds but need additional actions inside ${service}`}>
                <span className={"warning"}>Action Needed</span>
              </Tooltip>
            );
          }else{
            Content.push(
              <Tooltip key={index} title={`${service} fully complete collecting funds and paying out`}>
                <span className={"active"}>
                  {gateway === 'stripe' ? 'Enabled' : 'Active'}
                </span>
              </Tooltip>
            );
          }
        }
      });
    }
    if(Content.length){
      return Content;
    }else{
      return (
        <Tooltip title={`${service} not connected`}>
          <span className={"error"}>Not connected</span>
        </Tooltip>
      );
    }
  }

  const OfferStatus = (account) => {
    const Content = [];
    if(account){
      const offers = OfferList(account);
      offers.forEach((offer, index) => {
        Content.push(
          <TableRow key={index}>
            <StyledTableCell align='left'>
              {offer}
            </StyledTableCell>
            {MASTERS_GATEWAYS.map((gateway) => (
                <StyledTableCell align='left' className={classes.gatewayStatus}>
                  {ConnectionStatus(offer, gateway.name, account)}
                </StyledTableCell>
            ))}
          </TableRow>
        );
      });
    }
    if(Content.length){
      return Content;
    }else{
      return (
        <TableRow>
          <StyledTableCell align='left' className={"no-offers"}>
            No offers for this account
          </StyledTableCell>
        </TableRow>
      );
    }

  }

  return (
    <>
      {visibleAccounts?.length > 0 ? (

        <Grid
          id='all-accounts'
          container
          spacing={3}
          className={classes.adminContainer}
        >
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='left'>Brand Email</StyledTableCell>
                    <StyledTableCell align='left'>Offer</StyledTableCell>
                    {MASTERS_GATEWAYS.map((gateway) => (
                        <StyledTableCell align='left'>
                          <img src={gateway.url} alt={gateway.name} />
                        </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(visibleAccounts || []).map((account) => (
                      <StyledTableRow key={account.offer}>
                        <StyledTableCell align='left' style={{ verticalAlign: 'top', width: "25%" }}>
                          {account.email ? account.email : account.brand_email}
                        </StyledTableCell>
                        <StyledTableCell align='left' style={{ width: "75%" }} colSpan={MASTERS_GATEWAYS.length + 1} className={classes.offersListing}>
                          <Table>
                            {OfferStatus(account)}
                          </Table>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Button
              id='click-snackbar'
              style={{
                display: 'none',
              }}
              onClick={handleClickConnect(TransitionRight)}
            >
              Success
            </Button>
            <Snackbar
              open={openConnect}
              autoHideDuration={5000}
              onClose={handleConnect}
              TransitionComponent={transition}
              key={transition ? transition.name : ''}
            >
              <SnackbarContent
                style={{ backgroundColor: '#3CD4A0' }}
                message={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <CheckCircleIcon />
                    <span id='client-snackbar' style={{ padding: '0px 10px' }}>
                      Your account has been connected successfully...
                    </span>
                  </div>
                }
              />
            </Snackbar>
            <Button
              id='click-snackbar-error'
              style={{
                display: 'none',
              }}
              onClick={handleClickConnectError(TransitionTop)}
            >
              Error
            </Button>
            <Snackbar
              open={openConnectError}
              autoHideDuration={3000}
              onClose={handleConnectError}
              TransitionComponent={transitionError}
              key={transitionError ? transitionError.name : ''}
            >
              <SnackbarContent
                style={{ backgroundColor: 'red' }}
                message={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <ErrorOutlineIcon />
                    <span style={{ padding: '0px 10px' }}>
                      Please Select At Least 1 Brand...
                    </span>
                  </div>
                }
              />
            </Snackbar>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={12} alignItems="center">
              <FacebookCircularProgress />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default function AdminPage() {
  return (
    <>
      <PageTitle title={`Brand Overview`} />
      <SelectAccounts />
    </>
  );
}
