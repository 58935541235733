import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Styles
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import '@/assets/scss/App.scss';
import 'react-notifications/lib/notifications.css';
import Themes from './themes';

// components
import App from './App';
import { NotificationContainer } from 'react-notifications';

// Routes and User Context Layouts
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from '@/context/LayoutContext';
import { UserProvider } from '@/context/UserContext';
import { OffersProvider } from './context/OffersContext';

// Set up Amplify
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <OffersProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <React.StrictMode>
          <NotificationContainer />
          <Router>
            <App />
          </Router>
        </React.StrictMode>
      </ThemeProvider>
      </OffersProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById('gu-react-app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
