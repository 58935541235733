import { marketplaceService } from '@/services/marketplace';
import React from 'react';
import _mergeWith from  'lodash.mergewith';

const initialState = {
  brandPaymentOfferConfigs: null, // brand offers configs, i.e payment instructions, payment accounts...
  offers: null, // gu offers
};

const OffersStateContext = React.createContext();
const OffersDispatchContext = React.createContext();

const ACTION_TYPES = {
  FETCH_BRAND_PAYMENT_OFFER_CONFIGS: 'FETCH_BRAND_PAYMENT_OFFER_CONFIGS',
  UPDATE_BRAND_PAYMENT_INSTRUCTION: 'UPDATE_BRAND_PAYMENT_INSTRUCTION',
  FETCH_OFFERS: 'FETCH_OFFERS',
  REPLACE_BRAND_OFFER_CONFIG: 'REPLACE_BRAND_OFFER_CONFIG',
  REPLACE_OFFERS: 'REPLACE_OFFERS',
  RESET_INITIAL: 'RESET_INITIAL'
};

const offerActions = {
  fetchOffers: () => ({ type: ACTION_TYPES.FETCH_OFFERS }),
  replaceBrand: (brand) => ({ type: ACTION_TYPES.REPLACE_BRAND_OFFER_CONFIG, payload: { brand } }),
  replaceOffers: (offers) => ({ type: ACTION_TYPES.REPLACE_OFFERS, payload: { offers } }),
  resetInitialState: () => ({ type: ACTION_TYPES.RESET_INITIAL }),
  fetchBrandPaymentOffersConfigs: (email) => ({
    type: ACTION_TYPES.FETCH_BRAND_PAYMENT_OFFER_CONFIGS,
    payload: { email },
  }),
  updateBrandPaymentInstruction: (email, offer, paymentAccount, gateway) => ({
    type: ACTION_TYPES.UPDATE_BRAND_PAYMENT_INSTRUCTION,
    payload: { email, offer, paymentAccount, gateway },
  }),
};

async function handleFetchBrandPaymentOfferConfigs(state, payload) {
  try {
    const { email } = payload;
    const response = await marketplaceService.GetBrand(email);
    if (!response.success) return state;
    return { ...state, brandPaymentOfferConfigs: response.result };
  } catch (error) {
    return state;
  }
}

async function handleUpdateBrandPaymentInstruction(state, payload) {
  try {
    const { email, offer, paymentAccount, gateway } = payload;
    const response = await marketplaceService.UpdateBrandPaymentsOffer(email, paymentAccount,offer,gateway);
    if (!response.success) return state;
    return { ...state, brandPaymentOfferConfigs: response?.result?.newtItem };
  } catch (error) {
    return state;
  }
}

async function handleFetchOffers(state) {
  try {
    const response = await marketplaceService.GetAccountOffers();
    if (!response.success) return state;
    return { ...state, offers: response.result };
  } catch (error) {
    return state;
  }
}

async function asyncOffersReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.FETCH_BRAND_PAYMENT_OFFER_CONFIGS:
      return handleFetchBrandPaymentOfferConfigs(state, payload);
    case ACTION_TYPES.UPDATE_BRAND_PAYMENT_INSTRUCTION:
      return handleUpdateBrandPaymentInstruction(state, payload);
    case ACTION_TYPES.FETCH_OFFERS:
        return handleFetchOffers(state);
    case ACTION_TYPES.REPLACE_BRAND_OFFER_CONFIG:
        return { ...state, brandPaymentOfferConfigs: payload?.brand }
    case ACTION_TYPES.REPLACE_OFFERS:
        return { ...state, offers: payload?.offers }
    case ACTION_TYPES.RESET_INITIAL:
        return null;
    default: {
      console.error(`Received an Action Type [${type}] that is not yet implement`);
      return state;
    }
  }
}

function syncReducer(oldState, newState) {
  if (!newState) return { ...initialState }; // RESET_INITIAL;
  return _mergeWith({}, oldState, newState, (a, b) =>  b || a);
}

function OffersProvider({ children }) {
  const [state, syncDispatch] = React.useReducer(syncReducer, initialState);

  const dispatch = async (action) => {
    const newState  = await asyncOffersReducer(state, action);
    syncDispatch(newState);
  }

  return (
    <OffersStateContext.Provider value={state}>
      <OffersDispatchContext.Provider value={dispatch}>
        {children}
      </OffersDispatchContext.Provider>
    </OffersStateContext.Provider>
  );
}

function useOfferState() {
  const context = React.useContext(OffersStateContext);
  if (context === undefined) {
    throw new Error('useOfferState must be used within a OffersProvider');
  }
  return context;
}

function useOfferDispatch() {
  const context = React.useContext(OffersDispatchContext);
  if (context === undefined) {
    throw new Error('useOfferDispatch must be used within a OffersProvider');
  }
  return context;
}

export { OffersProvider, useOfferState, useOfferDispatch, offerActions };
