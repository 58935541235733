export const StripeCountries = [
  { country: "United States", code: "US" },
  { country: "Argentina", code: "AR" },
  { country: "Armenia", code: "AM" },
  { country: "Australia", code: "AU" },
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Bolivia", code: "BO" },
  { country: "Bosnia & Herzegovina", code: "BA" },
  { country: "Brazil", code: "BR" },
  { country: "Bulgaria", code: "BG" },
  { country: "Canada", code: "CA" },
  { country: "Cambodia", code: "KH" },
  { country: "Chile", code: "CL" },
  { country: "Colombia", code: "CO" },
  { country: "Costa Rica", code: "CR" },
  { country: "Croatia", code: "HR" },
  { country: "Cyprus", code: "CY" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Côte d'Ivoire", code: "CI" },
  { country: "Denmark", code: "DK" },
  { country: "Dominican Republic", code: "DO" },
  { country: "Egypt", code: "EG" },
  { country: "El Salvador", code: "SV" },
  { country: "Estonia", code: "EE" },
  { country: "Gambia", code: "GM" },
  { country: "Gibraltar", code: "GI" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Greece", code: "GR" },
  { country: "Hong Kong SAR China", code: "HK" },
  { country: "Hungary", code: "HU" },
  { country: "Iceland", code: "IS" },
  { country: "India", code: "IN" },
  { country: "Indonesia", code: "ID" },
  { country: "Ireland", code: "IE" },
  { country: "Israel", code: "IL" },
  { country: "Italy", code: "IT" },
  { country: "Japan", code: "JP" },
  { country: "Jordan", code: "JO" },
  { country: "Kenya", code: "KE" },
  { country: "Latvia", code: "LV" },
  { country: "Liechtenstein", code: "LI" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Macao SAR China", code: "MO" },
  { country: "Malaysia", code: "MY" },
  { country: "Malta", code: "MT" },
  { country: "Mauritius", code: "MU" },
  { country: "Mexico ", code: "MX" },
  { country: "Morocco ", code: "MA" },
  { country: "Namibia ", code: "NA" },
  { country: "Netherlands", code: "NL" },
  { country: "New Zealand", code: "NZ" },
  { country: "Norway", code: "NO" },
  { country: "Panama", code: "PA" },
  { country: "Paraguay", code: "PY" },
  { country: "Peru", code: "PE" },
  { country: "Philippines", code: "PH" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Romania", code: "RO" },
  { country: "Saudi Arabia", code: "SA" },
  { country: "Senegal", code: "SN" },
  { country: "Serbia", code: "RS" },
  { country: "South Korea", code: "KR" },
  { country: "South Africa", code: "ZA" },
  { country: "Singapore", code: "SG" },
  { country: "Slovakia", code: "SK" },
  { country: "Slovenia", code: "SI" },
  { country: "Spain", code: "ES" },
  { country: "Sweden", code: "SE" },
  { country: "Switzerland", code: "CH" },
  { country: "Thailand", code: "TH" },
  { country: "Trinidad & Tobago", code: "TT" },
  { country: "Tunisia", code: "TN" },
  { country: "Turkey", code: "TR" },
  { country: "United Arab Emirates", code: "AE" },
  { country: "United Kingdom", code: "GB" },
  { country: "Uruguay", code: "UY" },
  { country: "Vietnam", code: "VN" },
];
