import React from 'react';
import { authService } from '@/services/auth';
import { ROLE, LOCAL_STORAGE_KEYS } from '@/common/constants';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const ACTION_TYPES = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS'
}

const userActions = {
  loginSuccess: payload => ({ type: ACTION_TYPES.LOGIN_SUCCESS, payload })
};

function handleUserLoginSuccess(state, payload = {}) {
  const { user } = payload;
  const isAdmin = user?.role === ROLE.Admin || user?.attributes?.['custom:role'] === ROLE.Admin;

  // setting local storage keys
  localStorage.setItem(LOCAL_STORAGE_KEYS.CF_EMAIL, user.email);
  localStorage.setItem(LOCAL_STORAGE_KEYS.IS_ADMIN, isAdmin);
  localStorage.setItem(LOCAL_STORAGE_KEYS.ID_TOKEN, 1);

  return { ...state, isAuthenticated: true, user , isAdmin };
}

function userReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
     return handleUserLoginSuccess(state, action.payload || {});
    case ACTION_TYPES.SIGN_OUT_SUCCESS:
      return { ...state, isAuthenticated: false, user: null, isAdmin: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('id_token'),
    user: null,
    isAdmin: false
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, userActions };

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    setTimeout(() => {
      localStorage.setItem('id_token', 1)
      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })
      history.push('/app/payments')
    }, 5000);
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
}

async function signOut(dispatch, history) {
  await authService.signOut();
  localStorage.removeItem('id_token');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}
