import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  cancelBtn: {
    fontSize: 12,
    textTransform: "initial",
    color: "#000",
    border: '2px solid #B4C2C9',
    background: theme.palette.common.white,
    minWidth: '80px',
    height: '42px'
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: 12,
    border: `2px solid ${theme.palette.primary.main}`,
    textTransform: "initial",
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    minWidth: '80px',
    height: '42px'
  },
  instructions: {
    marginBottom: '20px',
  }
}));

