import { Auth } from 'aws-amplify';
import authApiClient from './apis/gu-auth-client';

// utils
import { format } from 'date-fns';
import { md5Hash } from '@/common/utils/helpers';

const success = (result) => ({ success: true, result });
const failure = (error) => ({ success: false, error });
const timestampTxt = () => `[${format(new Date(), 'MM/dd/yyyy - hh:mm:ss a')}]`;

export const authService = {
  async signIn(username, password) {
    try {
      const user = await Auth.signIn(`${username}`.trim().toLowerCase(), password);
      return success(user);
    } catch (error) {
      console.log(`${timestampTxt()} - error signIn() `, error);
      return failure(error);
    }
  },
  async forgotPassword(username) {
    try {
      const result = await Auth.forgotPassword(`${username}`.trim().toLowerCase());
      return success(result);
    } catch (error) {
      console.log(`${timestampTxt()} - error forgotPassword() `, error);
      return failure(error);
    }
  },
  async changePasswordWithCode(username, code, newPassword) {
    try {
      const result = await Auth.forgotPasswordSubmit(`${username}`.trim().toLowerCase(), code, newPassword);
      return success(result);
    } catch (error) {
      console.log(`${timestampTxt()} - error changePasswordWithCode() `, error);
      return failure(error);
    }
  },
  async getSessionUser() {
    try {
      const currentSession = await Auth.currentSession();
      // const currentUserInfo = await Auth.currentUserInfo(); // AWS cognito update broke this
      let currentUserInfo = currentSession.idToken;

      if (currentUserInfo.payload) {
        currentUserInfo.attributes = currentUserInfo.payload
      }

      const user = currentUserInfo?.attributes || {};
      user.role = user?.['custom:role']?.toLowerCase();
      user.gu_brand_id = md5Hash(user.email);

      return {
        user,
        accessToken: currentSession.getAccessToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken(),
        idToken: currentSession.getIdToken().getJwtToken()
      }
    } catch (error) {
      console.log('error getSessionUser(): ', error);
      return null;
    }
  },
  async signOut() {
    try {
      await Auth.signOut();
      return true;
    } catch (error) {
      console.log('error signOut(): ', error);
      return false;
    }
  },
  async adminSignUp(username, password) {
    try {
      const response =  await authApiClient.adminSignUp(`${username}`.trim().toLowerCase(), password);
      
      if (response.status > 399) {
        return failure(response?.data?.error || { error: { message: 'Error creating user' }});
      }

      return success(response);
    } catch (error) {
      console.log(`${timestampTxt()} - error in adminSignUp() `, error);
      return failure(error);
    }
  }
};
