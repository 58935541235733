import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  cardContainer: {
    marginTop: "10px",
    marginBottom: "48px",
  },
  accordion: {
    "&.Mui-expanded": {
      margin: "0",
      "&::before": {
        opacity: "1",
      }
    },
  },
  cardHeader: {
    paddingTop: "24px",
    paddingBottom: "24px",
    position: "relative",
    "& .offerName": {
      color: "#000",
      fontSize: "20px !important",
      fontWeight: "500 !important",
      margin: "0",
    },
    "& .checkIcon": {
      fill: "#fff",
      backgroundColor: "#40c264",
      borderRadius: "50%",
      padding: "6px",
      width: "30px",
      height: "30px",
      marginTop: "-15px",
      position: "absolute",
      top: "50%",
      right: "21px",
    }
  },
  cardBody: {
    borderTop: "1px solid #ededed",
    margin: "0",
    "& .serviceIcon img": {
      height: "22px",
    },
    "& .serviceHeader": {
      "& .serviceIcon": {
        flexGrow: "1",
        "& img": {
          position: "relative",
          top: "3px",
        }
      },
      "& .serviceActions": {
        flexGrow: "1",
        textAlign: "right",
        "& .dashboardButton": {
          fontSize: "13px",
          fontWeight: "400",
          color: "#04354B",
          whiteSpace: "nowrap",
          borderColor: "#e6ebed",
          display: "inline-block",
        },
        "& .serviceAlert": {
          display: "inline-block",
          "& .alertIcon": {
            fill: "#fa4616",
            height: "26px",
            width: "26px",
            marginLeft: "10px",
            display: "inline-block",
            position: "relative",
            top: "2px",
          },
        },
      },
    },
    "& .serviceDetails": {
      backgroundColor: "#f3f5f6",
      paddingTop: "20px",
      paddingBottom: "20px",
      flexDirection: "column",
      "& ul": {
        listStyle: "none",
        margin: "0",
        padding: "0",
        "& li": {
          padding: "3px 0",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000",
          "& span": {
            fontWeight: "700",
            display: "block",
          },
          "& button": {
            fontSize: "16px",
            fontWeight: "400",
            color: "#000",
            textDecoration: "underline",
          },
        },
      },
    },
  },
  dialogHeader: {
    marginTop: "10px",
    marginBottom: "0px",
  },
  dialogBody: {
    color: "#688692",
  }
}));
