import * as config from '@/config';

export const LOCAL_STORAGE_KEYS = {
  CF_EMAIL: 'cfEmail',
  OFFER_SELECTION: 'offers_selection',
  IS_ADMIN: 'is_admin',
  OFFERS: 'offers',
  ACCOUNT_NAME: 'account_name',
  BRAND: 'brand',
  ALL_ACCOUNTS: 'all_accounts',
  MANAGE_ACCOUNT: 'manage_account',
  RECONNECT_OFFERS: 'reconnect_offer',
  ID_TOKEN: 'id_token',
  STRIPE_ACCOUNT: 'stripe_account',
  GATEWAY: 'gateway',
  PAYMENT_INSTRUCTION: 'brand:payment_instructions'
}

export const OFFER_STATUS = {
  connected: 'connected',
  reConnected: 'reconnected',
  disconnected: 'disconnected',
  pending: 'Reconnect Not Completed',
  processConnection: 'processConnection',
}

export const BRAND_STATUS = {
  active: 'active',
  suspended: 'suspended',
  canceled: 'canceled',
}

export const GATEWAY = {
  paypal: 'paypal',
  stripe: 'stripe'
}

export const ROLE = {
  Admin: 'admin',
  Brand: 'brand'
};

export const MASTERS_GATEWAYS = [
  {
    name: "PayPal",
    //url: "https://cdn.giddyup.io/images/brands/paypal_logo.png",
    url: "https://cdn.giddyup.io/images/brands/paypal-logo.svg",
    tooltipTxt: "PayPal Marketplace Connect"
  },
  {
    name: "Stripe",
    //url: "https://cdn.giddyup.io/images/brands/stripe-payment-method.png",
    url: "https://cdn.giddyup.io/images/brands/stripe-logo.svg",
    tooltipTxt: "Stripe Marketplace On-Boarding"
  }
];

export const ALERT_MESSAGE = {
  reconnect: 'Please click “Update Account” button below to complete the reconnection. Your old account will continue to process transactions until the reconnect is completed.',
  doubleCheck: 'Reconnecting your current PayPal account or connecting a new one should only be done if absolutely necessary. This change might lead to disruptions to payment processing.',
  completeSignUp: 'To ensure your account is successfully connected, you must complete all of the steps during the sign up process for each Payment Gateway.',
  note: 'NOTE: Please register using your admin login, not a delegated user.',
  linkAccount: `Link your <b>PayPal</b> business account to <b>GiddyUp Payment Portal</b>`,
  stripeLink: `The Stripe link could not be retrieved.`,
}

export const ERROR_MESSAGE = {
  select_offer: 'Please Select at least 1 offer...',
  pending_status: 'Please complete the connection with the current offer...',
}

export const SUCCESS_MESSAGE = {
  account_connected: 'Your account has been connected successfully...'
}

export const GU_API_URL = config.GIDDYUP_API_URL;

export const GU_PAY_URL = config.GU_API_URL;

export const REACT_APP_ORIGIN = config.REACT_APP_ORIGIN;

export const STRIPE_URL = {
  refresh_url: `${REACT_APP_ORIGIN}/app/payments?action=refresh_url`,
  return_url: `${REACT_APP_ORIGIN}/app/payments?action=return_url`
}

export const PAYPAL_URL = {
  return_url: `${REACT_APP_ORIGIN}/app/payments?action=return_url`,
  dashboard: `https://www.paypal.com/us/signin`
}
