import * as appConfigs from './config';

const domain = new URL(window.location.href);

const awsconfig = {
  Auth: {
    region: appConfigs.AWS_REGION,
    userPoolId: appConfigs.COGNITO_POOL_ID,
    userPoolWebClientId: appConfigs.COGNITO_CLIENT_ID,
    cookieStorage: {
      domain: domain.hostname.toString(),
      path: "/",
      expires: 365,
      sameSite: "strict",
      secure: true,
    },
  },
};

export default awsconfig;
