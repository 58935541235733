import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  typo: {
    color: "#000",
    fontWeight: "500 !important",
    marginBottom: "20px !important",
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
}));
