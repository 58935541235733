import React, { useState, useRef, useEffect } from "react";

// components
import SwipeableViews from "react-swipeable-views";
import { Paper, Grid, withStyles, Button } from "@material-ui/core";
import WizardHeader from "./wizardHeader";
import SelectOffer from "./selectOffer";
import ConnectAccount from "./connectAccount";
import LoadingButton from "@/components/Buttons/LoadingButton";
import CountryDropDown from "@/components/CountryDropDown";

// constants
import { GATEWAY, STRIPE_URL, PAYPAL_URL, LOCAL_STORAGE_KEYS } from '@/common/constants';

// services
import { marketplaceService, PayPalPaymentAccount } from '@/services/marketplace';

// utils
import { guid, safeJSONParse } from "@/common/utils/helpers";

// context
import { useUserState } from "@/context/UserContext";
import { useLocalStorage } from "@/hooks/useLocalStorage";

const style = (theme) => ({
  root: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    overflow: "hidden",
    padding: "0px 0 0",
    margin: 0
  },
  gridPadding:{
    padding: "30px 20px",
    marginTop: "-7px",
    borderTop: "1px solid #e6ebed",
    "& button": {
      minWidth: "171px",
      [theme.breakpoints.down('sm')]: {
        minWidth: "150px",
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: "100px",
      },
    }
  },
  swipeContainer:{
    padding: "0px",
    '& [aria-hidden="true"][data-swipeable="true"]': {
      maxHeight: "0px",
      overflow: "hidden",
    }
  },
  panel: {
    backgroundColor: "red",
  },
  navigation: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    width: 110,
    fontSize: 12,
    textTransform: "initial",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      width: 90
    }
  },
  prevBtn: {
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    color: "#000",
    border: '2px solid #B4C2C9',
    background: theme.palette.common.white,
  },
  nextBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '12px',
    fontWeight: '600',
    textTransform: "initial",
    fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    minWidth: '130px',
    height: '42px'
  }
});

const SelectPayment = ({ brand_offers, classes }) => {
  const session = useUserState();
  const [brandOffers, setBrandOffers] = useLocalStorage(LOCAL_STORAGE_KEYS.OFFERS, {});
  const [isUpdatingBrand, setIsUpdatingBrand] = useState(false);
  const [disabledNextBtn, setDisabledNextBtn] = useState(true);
  const [loadingNextBtn, setLoadingNextBtn] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [country, setCountry] = useState(null);
  const [showStripeMoreInfoModal, setShowStripeMoreInfoModal] = useState(false);
  const tabs = ['Select offer', 'Connect account'];
  const timerRef = useRef();

  // Side Effects
  useEffect(() => () => {
    clearTimeout(timerRef.current);
    setDisabledNextBtn(true);
  }, []);

  useEffect(() => () => {
    setLoadingNextBtn(isUpdatingBrand);
  });

  // HANDLERS
  const handleNext = () => setActiveStep(activeStep + 1);

  const handlePrev = () => setActiveStep(activeStep - 1);

  const handleStep = () => {
    switch (activeStep) {
      case 0:
        return handleOfferSelected();
      case 1:
        return handlePaymentGatewaySelected();
      default:
        console.warn('unknown state for active step');
        break;
    }
  }

  const handleOfferSelected = () => {
    let offerSelection = JSON.parse(localStorage.getItem('offers_selection'));

    if (Array.isArray(offerSelection) && offerSelection[0]) {
      handleNext();
    }
  }

  const handlePaymentGatewaySelected = () => {
    const gateway = safeJSONParse(localStorage.getItem(LOCAL_STORAGE_KEYS.GATEWAY));
    if (gateway === GATEWAY.stripe && !country) {
      return collectMoreDataForStripe();
    }

    updateBrandDetails();
  }

  const offerSelected = () => {
    setDisabledNextBtn(false);
  }

  const collectMoreDataForStripe = () => {
    setShowStripeMoreInfoModal(true);
  }

  const onCountryClicked = () => {
    collectMoreDataForStripe();
  };

  async function updateBrandDetails() {
    try {
      setIsUpdatingBrand(true);
      let updateBrandResult;
      let onboardingLink;
      let returnUrl;
      let refreshUrl;
      let referenceId;

      const { user } = session;
      const gateway = safeJSONParse(localStorage.getItem(LOCAL_STORAGE_KEYS.GATEWAY));
      const offers = brandOffers;
      if (!offers.length) {
        marketplaceService.GetAccountOffers().then((res) => setBrandOffers(res.result));
      }
      const selectedOffer = safeJSONParse(localStorage.getItem(LOCAL_STORAGE_KEYS.OFFER_SELECTION));
      // the offer we are about to connect to a payment
      const paymentOffer = offers.find(offer => offer.offer === selectedOffer?.[0]);

      // stripe connect
      if (gateway === GATEWAY.stripe) {
        const stripeAccountResult  = await marketplaceService.CreateStripeAccount(user.email, country.code, paymentOffer.shopifydomain);

        if (!stripeAccountResult.success) {
          throw new Error(stripeAccountResult.error?.message || 'Fail creating stripe account');
        }

        const stripeAccount = stripeAccountResult?.result?.account;
        updateBrandResult = await marketplaceService.UpdateBrandStripePaymentsOffer(user.email, stripeAccount, paymentOffer);

        if (!updateBrandResult.success) {
          throw new Error(updateBrandResult.error?.message || 'Fail while making brand updates');
        }

        returnUrl = `${STRIPE_URL.return_url}&gu_offer=${paymentOffer.offer}`;
        refreshUrl = `${STRIPE_URL.refresh_url}&gu_offer=${paymentOffer.offer}`;
        const stripeOnboardingLink = await marketplaceService.StripeOnBoardingConnectUrl(stripeAccount.id, returnUrl, refreshUrl);

        if (!stripeOnboardingLink.success) {
          throw new Error(stripeOnboardingLink?.error?.message || 'Fail creating stripe onboarding link');
        }

        window.open(stripeOnboardingLink.result, '_self');
      }

      // paypal connect account
      if (gateway === GATEWAY.paypal) {
        referenceId = guid();
        const paypalAccount  = new PayPalPaymentAccount({ email: user.email, gu_paypal_reference_id: referenceId });
        returnUrl = `${PAYPAL_URL.return_url}&gu_offer=${paymentOffer.offer}&gu_paypal_reference_id=${referenceId}&service=paypal_connect`;

        onboardingLink = await marketplaceService.PayPalConnectUrl(user.gu_brand_id, returnUrl);

        if (!onboardingLink.success) {
          throw new Error(onboardingLink.error?.message || 'Failed creating onboarding link');
        }

        paypalAccount.connectUrl = onboardingLink.result;

        updateBrandResult = await marketplaceService.UpdateBrandPaypalPaymentsOffer(user.email, paypalAccount, paymentOffer);

        if (!updateBrandResult.success) {
          throw new Error(updateBrandResult.error?.message || 'Fail while making brand updates');
        }

        window.open(onboardingLink.result, '_self');
      }
    } catch (error) {
      console.log("Error in updateBrandDetails()", error);
    }

    setIsUpdatingBrand(false);
  }

  return (
    <Paper elevation={1} className={classes.root}>
      <CountryDropDown
        value={country}
        onChange={(c) => setCountry(c || "")}
        open={showStripeMoreInfoModal}
        onCancel={() => setCountry("") || setShowStripeMoreInfoModal(false)}
        onSave={() => setShowStripeMoreInfoModal(false)}
      />
      <WizardHeader tabs={tabs} activeStep={activeStep} />
      <form>
        <SwipeableViews index={activeStep} className={classes.swipeContainer}>
          <SelectOffer
            brand_offers={brand_offers}
            offerSelected={offerSelected}
          />
          <ConnectAccount
            brand_offers={brand_offers}
            country={country?.country}
            onCountryClicked={onCountryClicked}
          />
        </SwipeableViews>
        <Grid
          container
          justifyContent="space-between"
          className={classes.gridPadding}
        >
          <Grid item>
            {!!activeStep && (
              <Button
                disabled={activeStep === 0 || isUpdatingBrand}
                onClick={handlePrev}
                color="default"
                size="large"
                className={`${classes.navigation} ${classes.prevBtn}`}
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              id="next"
              color="primary"
              size="large"
              className={`${classes.navigation} ${classes.nextBtn}`}
              onClick={handleStep}
              disabled={disabledNextBtn || loadingNextBtn}
              loading={loadingNextBtn}
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default withStyles(style)(SelectPayment);
