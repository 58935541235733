import HttpClient from './httpClient';
import { GU_API_URL } from "@/common/constants";

const BASE_URL = `https://${GU_API_URL}/user-management`;

const httpClient = HttpClient();
httpClient.setBaseUrl(BASE_URL);

const adminSignUp = async (username, password) => {
  try {
    return await httpClient.post('/Admin-SignUp', { username, password });
  } catch (error) {
    return { data: null, status: 500, headers: {} };
  }
};

const client = {
  adminSignUp,
};

export default client;
