import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const GetCurrentSessionLoader = () => {
  return (
    <Box
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <CircularProgress size={60} />
        <h2 style={{ color: "#111" }}>
          Checking Browser Session
        </h2>
        <p style={{ color: "#111", fontSize: "1.125rem", lineHeight: "1.75rem" }}>
          You will be redirected to site immediately.
        </p>
      </div>
    </Box>
  );
};

export default GetCurrentSessionLoader;
