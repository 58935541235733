import tinycolor from "tinycolor2";

const primary = "#FA4616";
const secondary = "#000000";
const tertiary = "#688693";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#f1f1f1";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: tertiary,
      light: tinycolor(tertiary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(tertiary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#000000",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#f2f5f6",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "1000px",
        fontSize: "17px",
        textTransform: "capitalize",
        boxShadow: "none",
        "&:hover, &:focus": {
          boxShadow: "none",
        },
        "&$sizeLarge": {
          "& $label": {
            fontSize: "17px !important"
          }
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover, &:focus": {
          boxShadow: "none",
        },
      },
    },
    MuiRadio: {
      root: {
        color: "#b4c2c9",
      },
      colorPrimary: {
        "&$checked": {
          color: "#fa4616",
        },
      },
    },
    MuiChip:{
      root: {
        borderRadius: "6px",
        textTransform: "capitalize",
        padding: "0",
        "&.active": {
          backgroundColor: "#40c265",
          "& $label": {
            color: "#ffffff",
          }
        },
        "&.canceled": {
          backgroundColor: "#000000",
          "& $label": {
            color: "#ffffff",
          }
        },
        "&.suspended": {
          backgroundColor: "#688692",
          "& $label": {
            color: "#ffffff",
          }
        },
      },
      label: {
        fontSize: "16px",
        fontFamily: "'Rubik', Helvetica, Arial, sans-serif",
        fontWeight: "400",
        padding: "3px 8px",
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#f2f5f6",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10
      }
    },
  },
};

export default defaultTheme;
