import { useState } from "react";

const useService = (apiFunc, validatorFunc) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const service = async (...args) => {
    setLoading(true);
    const result = await apiFunc(...args);
    setLoading(false);

    const isValid = await validatorFunc?.(result);
    setError(!isValid);
    setResult(result);
    return result;
  };

  return { service, result, error, loading };
};

export default useService;
