import { GATEWAY } from '@/common/constants/index';

const valIsDefined = (val) => val !== undefined || val !== null;

/**
 * Payment Account Validator abstract class
 */
export class PaymentAccountValidator {
  constructor() {
    if (this.constructor === PaymentAccountValidator) {
      throw new Error(`Abstract classes can't be instantiated.`);
    }
  }

  isConnected() {
    throw new Error(`Method 'isConnected()' must be implemented.`);
  }

  isActionNeeded() {
    throw new Error(`Method 'isActionNeeded()' must be implemented.`);
  }
}

/**
 * Stripe Accounts Validator
 */
export class StripeAccountValidator extends PaymentAccountValidator {
  isConnected(account = {}) {
    if (!account) return false;
    const { charges_enabled, payouts_enabled } = account || {};
    return (
      !!account?.id &&
      valIsDefined(charges_enabled) &&
      valIsDefined(payouts_enabled)
    );
  }

  isActionNeeded(account) {
    if (!account) return true;
    const { charges_enabled, payouts_enabled } = account || {};
    const { card_payments, platform_payments } = account?.capabilities || {};

    return !(
      `${charges_enabled}`.toLowerCase() === 'true' &&
      `${payouts_enabled}`.toLowerCase() === 'true' &&
      `${card_payments}`.toLowerCase() === 'active' &&
      `${platform_payments}`.toLowerCase() === 'active'
    );
  }
}

/**
 * Paypal Account Validator
 */
export class PaypalAccountValidator extends PaymentAccountValidator {
  isConnected(account) {
    if (!account) return false;
    const { permissionsGranted, isEmailConfirmed, merchant_id } = account || {};
    // const payee = account?.payee?.merchant_id;

    return (
      `${permissionsGranted}`.toLowerCase() === 'true' &&
      `${isEmailConfirmed}`.toLowerCase() === 'true' &&
      valIsDefined(merchant_id)
    );
  }

  isActionNeeded(account) {
    if (!account) return true;
    return !this.isConnected(account);
  }
}

export class GatewayPaymentAccountValidator {
  constructor() {
    this.strategies_ = {
      [GATEWAY.paypal]: new PaypalAccountValidator(),
      [GATEWAY.stripe]: new StripeAccountValidator(),
    };
  }

  isConnected(gateway, account) {
    const strategy = this.strategies_[gateway];
    return !!(strategy && strategy.isConnected(account));
  }

  isActionNeeded(gateway, account) {
    const strategy = this.strategies_[gateway];
    return !!(strategy && strategy.isActionNeeded(account));
  }
}
