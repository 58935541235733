import HttpClient from './httpClient';
import { GU_API_URL } from "@/common/constants";

/* Marketplace APIs */
const BASE_URL = `https://${GU_API_URL}/marketplace`;
const httpClient = HttpClient();
httpClient.setBaseUrl(BASE_URL);

// Create Brand Account Details
const CreateBrand = async (gu_brand_id, marketplace_gateway, brand_name, email) => {
    try {
        return await httpClient.post('/create-account', { gu_brand_id, marketplace_gateway, brand_name, email });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

// Get Brand Account Details
const GetBrand = async (email, gu_brand_id) => {
    try {
        return await httpClient.post(`/get-account/${gu_brand_id}`, { email, gu_brand_id });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

// Get All Brand Accounts Details
const GetAllAccounts = async () => {
    try {
        return await httpClient.post(`/getall-accounts`, { });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

// Get Current Brand Offers Details
const GetAccountOffers = async (email, gu_brand_id) => {
    try {
        return await httpClient.post(`/get-account-offers/${gu_brand_id}`, { email });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

// Update Brand Details
const UpdateBrand = async (body) => {
    try {
        return await httpClient.put(`/${body?.gu_brand_id}/update-account`, {
            status: body?.status,
            updated_at: body?.updated_at,
            marketplace_gateway: body?.marketplace_gateway,
            brand_email: body?.brand_email,
            email: body?.email,
            brand_name: body?.brand_name,
            gu_brand_id: body?.gu_brand_id,
            payment_instruction: {
                [body?.gateway]: {
                    offers: body?.offers
                }
            },
            accountHolderDetails: {
                accountHolderCode: body?.gu_brand_id,
                    payments: {
                    [body?.gateway]: {
                        payment_offers: body?.payment_offers
                    }
                }
            }
        });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

const UpdateBrandSimple = async (body) => httpClient.put(`/${body?.gu_brand_id}/update-account`, body);

// Paypal Connect Action
const PayPalConnectUrl = async (gu_brand_id, return_url) => {
    try {
        return await httpClient.post('/paypal/createCustomerPartnerReferrals', { gu_brand_id, return_url });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

// Stripe Connect Action
const StripeOnBoardingConnectUrl = async (account_id, return_url, refresh_url) => {
    try {
        return await httpClient.post('/stripe/onboarding/link', { account_id, return_url, refresh_url });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

// Stripe Retrieve Account
const StripeRetrieveAccount = async (account_id) => {
    try {
        return await httpClient.get(`/stripe/accounts/${account_id}`, { account_id });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

const StripeLoginLinkUrl = async (account_id) => {
    try {
        return await httpClient.get(`/stripe/accounts/${account_id}/login_link`, { account_id });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

const StripeCreateAccount = async (email, country, shopifydomain) => {
    try {
        return await httpClient.post(`/stripe/accounts`, { email, country, shopifydomain });
    } catch (error) {
        return { data: null, status: 500, headers: {} };
    }
};

const client = {
    CreateBrand,
    GetBrand,
    GetAllAccounts,
    GetAccountOffers,
    UpdateBrand,
    PayPalConnectUrl,
    StripeOnBoardingConnectUrl,
    StripeRetrieveAccount,
    StripeLoginLinkUrl,
    UpdateBrandSimple,
    StripeCreateAccount
};

export default client;
