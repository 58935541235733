import marketplaceApiClient from './apis/gu-marketplace-client';
import { BRAND_STATUS, GATEWAY, STRIPE_URL, LOCAL_STORAGE_KEYS } from '@/common/constants';
import { UrlParams, timestampTxt, utcTimestampTxt, md5Hash, mergeDeep } from '@/common/utils/helpers'

const success = (result) => ({ success: true, result });
const failure = (error) => ({ success: false, error });

export const marketplaceService = {
    async CreateBrand(gu_brand_id, marketplace_gateway, brand_name, email) {
        try {
            const result = await marketplaceApiClient.CreateBrand(gu_brand_id, marketplace_gateway, brand_name, email);

            // failure
            if (result.status > 399) {
                return failure(result?.data?.error || { error: 'error creating brand' });
            }

            localStorage.setItem(LOCAL_STORAGE_KEYS.BRAND, JSON.stringify(result?.data));

            if (marketplace_gateway === 'stripe') {
                localStorage.setItem(LOCAL_STORAGE_KEYS.STRIPE_ACCOUNT, JSON.stringify(result?.data?.accountHolder?.stripe));
            }

            return success(result?.data);
        } catch (error) {
            console.log(`${timestampTxt()} - error CreateBrand() `, error);
            return failure(error);
        }
    },
    async GetBrand(email) {
        if (!email) {
            return failure({ error: { message: 'Cannot get brand of undefined' } });
        }

        try {
            const gu_brand_id = md5Hash(email);
            const result = await marketplaceApiClient.GetBrand(email, gu_brand_id);

            if (result.status > 399) {
                return failure(result?.error?.message || { error: { message: 'Error getting brand' } });
            }

            localStorage.setItem(LOCAL_STORAGE_KEYS.BRAND, JSON.stringify(result?.data));
            return success(result?.data);
        } catch (error) {
            console.log(`${timestampTxt()} - error GetBrand() `, error);
            return failure(error);
        }
    },
    async GetAllAccounts() {
        try {
            const result = await marketplaceApiClient.GetAllAccounts();
            localStorage.setItem(LOCAL_STORAGE_KEYS.ALL_ACCOUNTS, JSON.stringify(result?.data.accountHolder));
            return success(result?.data.accountHolder);
        } catch (error) {
            console.log(`${timestampTxt()} - error GetAllAccounts() `, error);
            return failure(error);
        }
    },
    async GetAccountOffers() {
        try {
            let email = localStorage.getItem('cfEmail').trim();
            const gu_brand_id = md5Hash(email);
            const result = await marketplaceApiClient.GetAccountOffers(email, gu_brand_id);
            localStorage.setItem('offers', JSON.stringify(result?.data?.offers));
            return success(result?.data?.offers);
        } catch (error) {
            console.log(`${timestampTxt()} - error GetAccountOffers() `, error);
            return failure(error);
        }
    },
    async UpdateBrand(gateway, brand_name, email, offers, gu_brand_id, redirect, isSuccess, payment_offers, status) {
        try {
            payment_offers = payment_offers || offers;

            const body = {
                status,
                updated_at: `${timestampTxt()}`,
                gateway,
                marketplace_gateway: gateway,
                brand_email: email,
                email: email,
                brand_name,
                gu_brand_id: gu_brand_id,
                payment_instruction: {
                    [gateway]: {
                        offers
                    }
                },
                accountHolderDetails: {
                    accountHolderCode: gu_brand_id,
                    payments:{
                        [gateway]: {
                            payment_offers
                        }
                    }
                }
            };

            const result = await marketplaceApiClient.UpdateBrand(body);

            if (result?.data && result.status === 200) {

                if (redirect === true && gateway === GATEWAY.paypal) {
                    await this.PayPalConnectUrl(gu_brand_id);
                    let keysToRemove = ['id_token', 'brand', 'offers_selection'];
                    keysToRemove.forEach(k =>
                        localStorage.removeItem(k))
                }

                if (redirect === true && gateway === GATEWAY.stripe) {
                    let stripe_account_id = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.STRIPE_ACCOUNT));
                    stripe_account_id = stripe_account_id?.id;

                    const selectedOffer = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.OFFER_SELECTION), [])?.[0];
                    const returnUrl = `${STRIPE_URL.return_url}&gu_offer=${selectedOffer}`;
                    const refreshUrl = `${STRIPE_URL.refresh_url}&gu_offer=${selectedOffer}`;

                    const stripeLink = await this.StripeOnBoardingConnectUrl(stripe_account_id, refreshUrl, returnUrl);

                    let keysToRemove = [
                      LOCAL_STORAGE_KEYS.ID_TOKEN,
                      LOCAL_STORAGE_KEYS.BRAND,
                      LOCAL_STORAGE_KEYS.OFFER_SELECTION,
                      LOCAL_STORAGE_KEYS.STRIPE_ACCOUNT,
                    ];

                    keysToRemove.forEach(k => localStorage.removeItem(k));

                    if (stripeLink && !stripeLink?.error) {
                        window.open(stripeLink, '_self');
                    }
                }

                if (isSuccess === true) {
                    window.history.replaceState(null, null, window.location.pathname);

                    setTimeout(function run() {
                        window.location.reload(false);
                    }, 3000);

                    let keysToRemove = ['id_token', 'brand', 'offers_selection'];
                    keysToRemove.forEach(k =>
                        localStorage.removeItem(k))

                    let clickSnackbar = document.getElementById('click-snackbar');

                    if (clickSnackbar) {
                        clickSnackbar.click();
                    }

                }

                if (
                    (result?.data?.accountHolder?.status === BRAND_STATUS.suspended ||
                    result?.data?.accountHolder?.status === BRAND_STATUS.canceled ||
                    result?.data?.accountHolder?.status === BRAND_STATUS.active) &&
                    gateway !== GATEWAY.stripe
                ) {

                    let keysToRemove = ['manage_account', 'all_accounts'];
                    keysToRemove.forEach(k =>
                        localStorage.removeItem(k))

                    let clickSnackbar = document.getElementById('click-snackbar');

                    if (clickSnackbar) {
                        clickSnackbar.click();
                    }

                    setTimeout(function run() {
                        window.location.reload(false);
                    }, 3000);

                }

                return success(result?.data);
            }

        } catch (error) {
            console.error(`${timestampTxt()} - error UpdateBrand() `, error);
            return failure(error);
        }
    },
    async PayPalConnectUrl(gu_brand_id, return_url) {
        try {
            let paypalLink = await marketplaceApiClient.PayPalConnectUrl(gu_brand_id, return_url);

            if (paypalLink.status > 399) {
                return failure({error: { message: 'Failed getting paypal onboarding link' }});
            }

            paypalLink = paypalLink.data?.links[1].href;
            return success(paypalLink);
        } catch (error) {
            console.log(`${timestampTxt()} - error PayPalConnectUrl() `, error);
            return failure(error);
        }
    },
    async StripeOnBoardingConnectUrl(account_id, return_url, refresh_url) {
        try {
            let response = await marketplaceApiClient.StripeOnBoardingConnectUrl(account_id, return_url, refresh_url);

            if (response.status > 399) {
                return failure(response.data.error);
            }

            return success(response?.data?.accountLink?.url);
        } catch (error) {
            console.log(`${timestampTxt()} - error StripeOnBoardingConnectUrl() `, error);
            return failure(error);
        }
    },
    async StripeRetrieveAccount(account_id) {
        try {
            let result = await marketplaceApiClient.StripeRetrieveAccount( account_id );

            if (result.status > 399 || !result?.data?.account) {
                return failure(result.data.error);
            }

            localStorage.setItem(LOCAL_STORAGE_KEYS.STRIPE_ACCOUNT, JSON.stringify(result?.data?.account));
            return success(result?.data?.account);
        } catch (error) {
            console.log(`${timestampTxt()} - error StripeRetrieveAccount() `, error);
            return failure(error);
        }
    },
    async StripeLoginLinkUrl(account_id) {
        try {
            let result = await marketplaceApiClient.StripeLoginLinkUrl(account_id);

            if (result.status > 399) {
                return failure(result.data?.error || 'Error occurred while creating Stripe Link');
            }

            return success(result);
        } catch (error) {
            console.log(`${timestampTxt()} - error LoginLinkUrl() `, error);
            return failure(error);
        }
    },
    async CreateStripeAccount(email, countryCode, shopifydomain) {
        try {
            const response = await marketplaceApiClient.StripeCreateAccount(email, countryCode, shopifydomain);

            if (response.status > 399) {
                return failure(response.data?.error);
            }

            return success({ account: response?.data?.account });
        } catch (error) {
            console.log('Error in UpdateBrandStripePayments', error);
            return failure(error);
        }
    },
    async UpdateBrandStripePaymentsOffer(email, stripeAccount, offer) {
        return await this.UpdateBrandPaymentsOffer(email, stripeAccount, offer, GATEWAY.stripe);
    },
    async UpdateBrandPaypalPaymentsOffer(email, paypalAccount, offer) {
        return await this.UpdateBrandPaymentsOffer(email, paypalAccount, offer, GATEWAY.paypal);
    },
    async UpdateBrandPaymentsOffer(email, paymentAccount, offer, gateway) {
        try {
            const { result: brand } = await this.GetBrand(email); // get the latest brand.
            const accountHolder = brand?.accountHolder;
            const paymentOffer = Object.assign({}, offer, paymentAccount, { updated: utcTimestampTxt() }); // payment+offer in one object
            let accountId = (paymentAccount) => paymentAccount.id || paymentAccount.merchant_id || paymentAccount.gu_paypal_reference_id;

            if (!brand) {
                throw new Error('UpdateBrandPaymentsOffer failed to get brand');
            }

            let offers = [paymentOffer];
            if (Array.isArray(accountHolder?.payment_instruction?.[gateway]?.offers)) {
                const existingOffers = accountHolder?.payment_instruction?.[gateway]?.offers;
                const indexExistingOffer = existingOffers.findIndex(el => el.offer === offer.offer);

                if (indexExistingOffer !== -1) {
                    const updateOfferPayment = mergeDeep(existingOffers[indexExistingOffer], paymentOffer);
                    existingOffers[indexExistingOffer] = updateOfferPayment;
                    offers = existingOffers;
                } else {
                    offers = existingOffers.concat(paymentOffer);
                }
            }

            let gatewayPaymentAccounts = [Object.assign({}, paymentAccount)];
            if (Array.isArray(accountHolder?.payment_accounts?.[gateway])) {
                const existingGatewayPaymentAccounts = accountHolder?.payment_accounts?.[gateway];
                const indexExistingPaymentAccount = existingGatewayPaymentAccounts.findIndex(el => accountId(el) === accountId(paymentAccount));

                if (indexExistingPaymentAccount !== -1) {
                    const updatePaymentAccount = mergeDeep(existingGatewayPaymentAccounts[indexExistingPaymentAccount], paymentAccount);
                    existingGatewayPaymentAccounts[indexExistingPaymentAccount] = updatePaymentAccount
                    gatewayPaymentAccounts = existingGatewayPaymentAccounts;
                } else {
                    gatewayPaymentAccounts = gatewayPaymentAccounts.concat(existingGatewayPaymentAccounts);
                }
            }

            const update = {
                account_status: 'Updated',
                updated_at: utcTimestampTxt(),
                payment_instruction: {
                    ...(accountHolder?.payment_instruction || {}),
                    [gateway]: {
                        offers
                    }
                },
                payment_accounts: {
                    ...(accountHolder.payment_accounts || {}),
                    [gateway]: gatewayPaymentAccounts
                }
            };

            const newtItem = Object.assign({}, accountHolder, update);
            const result = await marketplaceApiClient.UpdateBrandSimple(newtItem);

            if (result.status > 399) {
                return failure(result?.data?.error || { error: { message: 'Failed while updating' } });
            }

            return success({ result, brand: { ...brand, accountHolder: newtItem } });
        } catch (error) {
            console.log('Error in UpdateBrandPaymentsOffer', offer, gateway, error);
            return failure(error);
        }
    }
};

/* Payment and Marketplace actions */

// Local Storage Data State
export const LocalStorageData = async () => {
    // Local Data
    let email = localStorage.getItem('cfEmail');
    const gu_brand_id = md5Hash(email);
    const gateway = JSON.parse(localStorage.getItem('gateway'));
    const { result: account } = await marketplaceService.GetBrand(email);
    const brand = JSON.parse(localStorage.getItem('brand'));

    let stripeResults;
    if (brand?.accountHolder?.stripe && gateway === GATEWAY.stripe) {
        stripeResults = brand ? await marketplaceService.StripeRetrieveAccount(brand?.accountHolder?.stripe?.id) : [];
    }

    return {
        email,
        gateway,
        gu_brand_id,
        brand_name: email,
        brand,
        offers: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.OFFERS)),
        offerSelection: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.OFFER_SELECTION)),
        account,
        paypalResults: UrlParams(window.location.search),
        stripeResults: stripeResults?.result?.data?.account || JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.STRIPE_ACCOUNT)) || [],
    }
}

export function PayPalPaymentAccount(props) {
    return {
        merchant_id: null,
        merchantIdInPayPal: null,
        accountStatus: null,
        isEmailConfirmed: null,
        productIntentID: null,
        permissionsGranted: null,
        consentStatus: null,
        connectUrl: null,
        payee: {
            merchant_id: null
        },
        ...props,
    }
};

// Input payment_instruction object { stripe: { offers: [] }, paypal: { offer: [] } }
// { 'offer_name_1': { stripe: {}, paypal: {}, ... }, 'offer_name_2': { stripe: {}, paypal: {}, ... } }
export const paymentInstructionsGroupByOffer = (paymentInstructionOffer = {}) => {
  const brandOffers = {};

  const gateways = Object.keys(paymentInstructionOffer);

  gateways.forEach((gateway) => {
    let offers = [];
    if (Array.isArray(paymentInstructionOffer[gateway]?.offers)) {
      offers = paymentInstructionOffer[gateway].offers;
    }

    offers.forEach((offer) => {
      const key = offer?.offer; // offer name such 'aculief.io'

      if (!brandOffers[key]) brandOffers[key] = {};
      brandOffers[key][gateway] = offer; // offer here is payment_offer config.
    });
  });

  return brandOffers;
};

export const isPaypalConnectionActive = (account) => {
  if (!account) return false;
  const { permissionsGranted, isEmailConfirmed, merchant_id } = account;
  return (`${permissionsGranted}` === 'true' && `${isEmailConfirmed}` === 'true' && merchant_id);
};

export const isStripeAccountActive = (account) => {
  if (!account) return false;
  const { charges_enabled } = account;
  return `${charges_enabled}` === 'true';
};

export const isAccountActive = (account, gateway) => {
  if (gateway === GATEWAY.stripe) return isStripeAccountActive(account);
  if (gateway === GATEWAY.paypal) return isPaypalConnectionActive(account);
  return false;
};