import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import Grid from '@material-ui/core/Grid';
import PageTitle from "@/components/PageTitle";
import FullScreenLoader from '@/components/Loaders/FullScreenLoader';
import OfferPaymentDetails from '@/components/OfferPaymentDetails/OfferPaymentDetails';
import SelectPayment from "@/components/BrandSettings/selectPayment";
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';

// Notification Component
import { NotificationManager } from 'react-notifications';

import useStyles from './styles';

// services
import { marketplaceService, paymentInstructionsGroupByOffer, PayPalPaymentAccount } from '@/services/marketplace';

// utils
import { safeJSONParse, timestampTxt, UrlParams } from '@/common/utils/helpers';
import { GatewayPaymentAccountValidator } from '@/common/utils/PaymentAccountValidator';

// constants
import { STRIPE_URL, MASTERS_GATEWAYS, LOCAL_STORAGE_KEYS } from '@/common/constants';

// Context
import { useUserState } from '@/context/UserContext';

// custom hooks
import { offerActions, useOfferDispatch, useOfferState } from '@/context/OffersContext';
import useService from '@/hooks/useService';

const paymentAccountValidator = new GatewayPaymentAccountValidator();

export default function PaymentsPage() {
  // state
  const [offersPaymentConfigGroupByOfferName, setOffersPaymentConfigGroupByOfferName] = useState();
  const [selectOffers, setSelectOffers] = useState();
  const [loading, setLoading] = useState(false);
  
  // styles
  const classes = useStyles();

  // hooks
  const { user } = useUserState();
  const { offers, brandPaymentOfferConfigs } = useOfferState();
  const offersDispatch = useOfferDispatch();
  const history = useHistory();
  const browserLocation = useLocation();

  // services hooks
  const {
    loading: loadingBrand,
    service: fetchBrand,
    error: errorFetchingBrand
  } = useService(
    async (email) => {
      const response = await marketplaceService.GetBrand(email);
      await offersDispatch(offerActions.replaceBrand(response?.result || {}));
      return response;
    },
    (r) => r?.success);

  const {
    loading: loadingOffers,
    service: fetchOffers,
    error: errorFetchingOffers
  } = useService(
    async () => {
      const response = await marketplaceService.GetAccountOffers();
      await offersDispatch(offerActions.replaceOffers(response?.result || []));
      return response;
    },
    (r) => r?.success);

  const handleStripeRefreshUrl = useCallback(
    async (account_id, offer) => {
      setLoading(true);
      await marketplaceService.StripeRetrieveAccount(account_id).catch(console.error);
      const returnUrl = `${STRIPE_URL.return_url}&gu_offer=${offer}`;
      const refreshUrl = `${STRIPE_URL.refresh_url}&gu_offer=${offer}`;

      marketplaceService
        .StripeOnBoardingConnectUrl(account_id, returnUrl, refreshUrl)
        .then((stripeLink) => window.open(stripeLink.result, '_self'))
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          history.push(browserLocation.pathname);
        });
    }, [history, browserLocation]);
    
  // Handlers
  const handleStripeReturnUrl = useCallback(
    async (account_id, offer) => {
      try {
        setLoading(true);
        const response = await marketplaceService.StripeRetrieveAccount(account_id);

        if (!response.success) {
          throw new Error(response.error?.message || 'Error retrieving stripe account');
        }

        const stripeAccount = response.result;
        const email = user.email;
        const savedOffers = safeJSONParse(localStorage.getItem(LOCAL_STORAGE_KEYS.OFFERS), []);
        const paymentInstructionOffer = savedOffers.find((savedOffer) => savedOffer?.offer === offer);

        const updateBrandResponse = await marketplaceService.UpdateBrandStripePaymentsOffer(email, stripeAccount, paymentInstructionOffer);

        if (!updateBrandResponse.success) {
          throw new Error(updateBrandResponse.error?.message || 'Failed updating account');
        }

        NotificationManager.success('Successfully saved stripe account', 'Stripe Account Update', 5000);
        await offersDispatch(offerActions.replaceBrand(updateBrandResponse.result.brand));
      } catch (error) {
        console.error('Error in handleStripeReturnUrl() ', error);
        NotificationManager.error(error.message, 'Stripe Account Update', 10000);
      }

      setLoading(false);
      history.push(browserLocation.pathname);
    },
    [user.email, history, browserLocation]
  );

  const handlePaypalReturnUrl = useCallback(
    async (queryParams) => {
      try {
        setLoading(true);
        const offer = queryParams.gu_offer;

        const paypalAccount = new PayPalPaymentAccount({
          merchant_id: queryParams.merchantId,
          merchantIdInPayPal: queryParams.merchantIdInPayPal,
          accountStatus: queryParams.accountStatus,
          isEmailConfirmed: queryParams.isEmailConfirmed,
          productIntentID: queryParams.productIntentID,
          permissionsGranted: queryParams.permissionsGranted,
          consentStatus: queryParams.consentStatus,
          gu_paypal_reference_id: queryParams.gu_paypal_reference_id,
          payee: {
            merchant_id: queryParams.merchantIdInPayPal,
          },
        });

        const email = user.email;
        const savedOffers = safeJSONParse(localStorage.getItem(LOCAL_STORAGE_KEYS.OFFERS), []);
        const paymentInstructionOffer = savedOffers.find((savedOffer) => savedOffer?.offer === offer);
        const updateBrandResponse = await marketplaceService.UpdateBrandPaypalPaymentsOffer(email, paypalAccount, paymentInstructionOffer);

        if (!updateBrandResponse.success) {
          throw new Error(updateBrandResponse.error?.message || 'Failed updating account');
        }

        NotificationManager.success('Successfully saved paypal account', 'Paypal Account Update', 5000);
        await offersDispatch(offerActions.replaceBrand(updateBrandResponse.result.brand));
      } catch (error) {
        console.error('Error in handlePaypalReturnUrl() ', error);
        NotificationManager.error(error.message, 'Paypal Account Update', 10000);
      }

      setLoading(false);
      history.push(browserLocation.pathname);
    },
    [user.email, history, browserLocation]
  );

  // use effect side effects
  useEffect(() => {
    if (!brandPaymentOfferConfigs) {
      fetchBrand(user.email);
    }

    if (!offers?.length) {
      fetchOffers();
    }
  }, []);

  useEffect(() => {
    if (brandPaymentOfferConfigs?.accountHolder) {
      const groups = paymentInstructionsGroupByOffer(brandPaymentOfferConfigs?.accountHolder?.payment_instruction || {});
      setOffersPaymentConfigGroupByOfferName(groups);
    }
  }, [brandPaymentOfferConfigs]);

  useEffect(() => {
    if (offersPaymentConfigGroupByOfferName && offers) {
      const selectOffers = {};

      offers.forEach(offer => {
        const offerName = offer?.offer;
        const payments = offersPaymentConfigGroupByOfferName[offerName] || {};

        const offerSetupIsComplete = MASTERS_GATEWAYS.every(el => {
          const gateway = el.name.toLowerCase();
          const account = payments[gateway];
          return paymentAccountValidator.isConnected(gateway, account);
        });

        if (!offerSetupIsComplete) {
          selectOffers[offerName] = offersPaymentConfigGroupByOfferName[offerName] || {};
        }
      });

      setSelectOffers(selectOffers);
    }
  }, [offersPaymentConfigGroupByOfferName, offers]);

  useEffect(() => {
    const { pathname, search } = browserLocation;
    const query = UrlParams(search);

    // stripe
    if (pathname.startsWith('/app/payments') && query?.action === 'refresh_url' && query.service === 'stripe_connect') {
      console.log('stripe refresh_url');
      handleStripeRefreshUrl(query.account_id, query.gu_offer);
    }

    if (pathname.startsWith('/app/payments') && query?.action === 'return_url' && query.service === 'stripe_connect') {
      console.log('stripe return_url');
      handleStripeReturnUrl(query.account_id, query.gu_offer);
    }

    // paypal
    if (query.service === 'paypal_connect') {
      console.log(`${timestampTxt()} - paypal_connect redirect`);

      // handle paypal after redirection
      handlePaypalReturnUrl(query);
    }
  }, [browserLocation, handleStripeReturnUrl, handleStripeRefreshUrl, handlePaypalReturnUrl]);

  useEffect(() => {
    if (errorFetchingBrand) {
      NotificationManager.error('Error fetching brand', 'Error', 10000);
    }
  }, [errorFetchingBrand]);

  useEffect(() => {
    if (errorFetchingOffers) {
      NotificationManager.error('Error fetching offers', 'Error', 10000);
    }
  }, [errorFetchingOffers]);

  useEffect(() => {
    setLoading(loadingOffers && loadingBrand);
  }, [loadingOffers, loadingBrand]);

  return (
    <div style={{ width: "100%" }}>
      <PageTitle title="Manage Payment Gateways" />
      <FullScreenLoader open={loading} />
      {!loading && offers?.length === 0 && (
        <Grid container alignContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <ErrorIcon style={{ fontSize: "48px", color: "#ef4444" }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              No offers found for brand with email <strong>{user.email}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}
      {!loading && brandPaymentOfferConfigs && !Object.keys(brandPaymentOfferConfigs).length && (
        <Grid container alignContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <ErrorIcon style={{ fontSize: "48px", color: "#ef4444" }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              No brand configs found for brand email <strong>{user.email}</strong>
            </Typography>
          </Grid>
        </Grid>
      )}
      {!loadingBrand && offersPaymentConfigGroupByOfferName && (
        <OfferPaymentDetails
          brand_offers={offersPaymentConfigGroupByOfferName}
        />
      )}
      {!loading && selectOffers && !!Object.keys(selectOffers).length && (
        <>
          <Typography variant="h5" className={classes.typo} noWrap>
            Connect All Accounts Listed Below
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectPayment brand_offers={offersPaymentConfigGroupByOfferName} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
