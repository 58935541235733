import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Autorenew from "@material-ui/icons/Autorenew";

import { generateStrongPassword } from "@/common/utils/helpers";

const PasswordTextField = ({ children, type, withRandomPasswordGenerator, onRandomPasswordGenerated, ...props }) => {
  const [visible, setIsVisible] = useState(false);

  const generateRandomPassword = () => {
    let randomPassword = generateStrongPassword(20);
    onRandomPasswordGenerated?.(randomPassword);
  };

  return (
    <>
      <TextField
        type={visible ? "text" : "password"}
        {...props}
        InputProps={{
          endAdornment: (
            <>
              {withRandomPasswordGenerator && (
                <InputAdornment
                  position="end"
                  onClick={generateRandomPassword}
                  style={{ cursor: "pointer" }}
                >
                  <Tooltip title="random password">
                    <Autorenew />
                  </Tooltip>
                </InputAdornment>
              )}
              <InputAdornment
                position="end"
                onClick={() => setIsVisible(!visible)}
                style={{ cursor: "pointer" }}
              >
                {visible ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            </>
          ),
        }}
      />
    </>
  );
};

export default PasswordTextField;
