import { useUserState } from '@/context/UserContext';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

// pages
import Layout from '@/components/Layout/Layout';
import Payments from '@/pages/payments';
import Admin from '@/pages/admin';
import AddBrand from '@/pages/add-brand';

const PrivateRoutes = [
  {
    path: '/app',
    name: 'LayoutComponent',
    component: function RedirectComponent() {
      const session = useUserState();
      const history = useHistory();

      useEffect(() => {
        if (!session?.user) history.push('/login');
        else if (session?.isAdmin && session.user) history.push('/app/admin');
        else if (session?.user && !session?.isAdmin) history.push('/app/payments');
      }, [session, history]);

     return (<></>);
    },
    metadata: { isPrivate: true, exact: true, sidebarLayout: true },
  },
  {
    path: '/app/admin',
    name: 'ManageBrands',
    component: Admin,
    metadata: {
      isPrivate: true,
      exact: true,
      admin: true,
      sidebarLayout: true,
    },
  },
  {
    path: '/app/new-brand',
    name: 'ManageBrands',
    component: AddBrand,
    metadata: {
      isPrivate: true,
      exact: true,
      admin: true,
      sidebarLayout: true,
    },
  },
  {
    path: '/app/payments',
    name: 'Brands',
    component: Payments,
    metadata: { isPrivate: true, exact: true, sidebarLayout: true },
  },
];

const withAuthenticator = (WrappedComponent, metadata) => {
  const Wrapper = () => {
    const { user } = useUserState();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      if (!user) {
        const next = encodeURIComponent(`${location.pathname}${location.search}`);
        history.push(`/login${location.search && `?next=${next}`}`);
      }

      if (user && user.role !== 'admin' && metadata.admin) {
        history.push(`/app`);
      }
    }, [user, history, location]);

    return user && <WrappedComponent />;
  };

  return Wrapper;
};

const wrapWithSideBarLayout = (WrappedComponent) => {
  const Wrapper = () => {
    return (
      <Layout>
        <WrappedComponent />
      </Layout>
    );
  };

  return Wrapper;
};

PrivateRoutes.forEach((route) => {
  let component = withAuthenticator(route.component, route.metadata);

  if (route.metadata.sidebarLayout) {
    component = wrapWithSideBarLayout(component);
  }

  route.component = component;
});

export default PrivateRoutes;
