import React from 'react';

// components
import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LoadingButton from '@/components/Buttons/LoadingButton';
import { NotificationManager } from 'react-notifications';

// form libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// style helpers
import useStyles from './styles';

// services
import { authService } from '@/services/auth';

// custom hooks
import useService from '@/hooks/useService';
import PasswordTextField from '@/components/Input/PasswordTextField';
import PageTitle from "@/components/PageTitle";

// validators as schemas using yup library
const validationSchema = yup.object({
  // brandName: yup
  //   .string('Enter the brand name')
  //   .min(2, 'Brand Name should be a minimum of two characters')
  //   .required('Brand Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/(?=.*[A-Z])/, 'Password requires uppercase letters')
    .matches(/(?=.*[a-z])/, 'Password requires lowercase letters')
    .matches(/(?=.*[0-9])/, 'Password must contain numbers')
    .matches(/(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])/, 'Password must contain special character !@#%&?^$*[]{}()')
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password must match'),
});

const AddBrandForm = () => {
  const classes = useStyles();
  const adminSignUp = useService(authService.adminSignUp, (v) => v.success);

  const formik = useFormik({
    initialValues: {
      brandName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const { email, password } = values;
      const result = await adminSignUp.service(email, password);

      if (!result.success) {
        return NotificationManager.error(result.error.message, 'Error', 10000);
      }

      resetForm();
      NotificationManager.success('Successfully created new user', 'New User', 5000);
    },
    validateOnMount: true,
  });

  const resetForm = () => {
    formik.resetForm();
    formik.validateForm();
  };

  return (
    <>
    <PageTitle title={`Create Brand Account`} />
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={12} className={classes.addContainer}>
          <Paper>
            <form onSubmit={formik.handleSubmit} className={classes.root}>
              <h3>New Account</h3>
              {/* <TextField
                fullWidth
                variant='outlined'
                id='brand-name'
                name='brandName'
                label='Brand Name'
                value={formik.values.brandName}
                onChange={formik.handleChange}
                error={
                  formik.touched.brandName && Boolean(formik.errors.brandName)
                }
                helperText={formik.touched.brandName && formik.errors.brandName}
                onBlur={formik.handleBlur}
                disabled={adminSignUp.loading}
              /> */}
              <TextField
                fullWidth
                variant='outlined'
                id='email'
                name='email'
                label='Email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                disabled={adminSignUp.loading}
              />
              <PasswordTextField
                fullWidth
                withRandomPasswordGenerator
                disabled={adminSignUp.loading}
                variant='outlined'
                id='password'
                name='password'
                label='Password'
                type='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onRandomPasswordGenerated={(randomPassword) => {
                  formik.setValues({
                    ...formik.values,
                    passwordConfirmation: randomPassword,
                    password: randomPassword,
                  });
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                onBlur={formik.handleBlur}
              />
              <PasswordTextField
                fullWidth
                disabled={adminSignUp.loading}
                variant='outlined'
                id='password-confirmation'
                name='passwordConfirmation'
                label='Confirm Password'
                type='password'
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                onBlur={formik.handleBlur}
              />
              <Grid
                container
                alignContent='flex-end'
                direction='row'
                spacing={2}
                className={classes.actions}
              >
                <Grid item xs={12}>
                  <LoadingButton
                    color='primary'
                    variant='contained'
                    fullWidth
                    type='submit'
                    loading={adminSignUp.loading}
                    disabled={adminSignUp.loading || !formik.isValid}
                  >
                    Create account
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default AddBrandForm;
