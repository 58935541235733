import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";

// icons
import PaymentOutlined from "@material-ui/icons/PaymentOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";

// styles, classes, and themes
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "@/context/LayoutContext";
import { useUserState } from "@/context/UserContext";

// hoc
import { withRouter} from "react-router-dom";


const randomId = () => Math.random().toString(36).substr(2);

const adminOnlySidebarLinks = [
  { id: randomId(), label: "Brand Overview", link: "/app/admin", icon: <AccountCircle /> },
  { id: randomId(), label: "Add Brand", link: "/app/new-brand", icon: <Add /> }
];

const brandOnlyLinks = [
  { id: randomId(), label: "Payment Gateways", link: "/app/payments", icon: <PaymentOutlined /> }
];

const commonLinks = [];

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();
  const [sidebarLinks, setSidebarLinks] = useState(commonLinks);
  const session = useUserState();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const [isSidebarShown, setSidebarShown] = useState(true);

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    if (session.user && session.isAdmin) {
      setSidebarLinks(commonLinks.concat(adminOnlySidebarLinks));
    } else if (session.user && !session.isAdmin) {
      //setSidebarLinks(commonLinks.concat(brandOnlyLinks));
      setSidebarShown(false);
    }
  }, [session]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarShown && isSidebarOpened,
        [classes.drawerClose]: isSidebarShown && !isSidebarOpened,
        [classes.drawerHidden]: !isSidebarShown,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarShown && isSidebarOpened,
          [classes.drawerClose]: isSidebarShown && !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {sidebarLinks.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    /* TODO: Fix sidebar responsiveness for smaller screens */
    // if (isSmallScreen && isPermanent) {
    //   setPermanent(false);
    // } else if (!isSmallScreen && !isPermanent) {
    //   setPermanent(true);
    // }
  }
}

export default withRouter(Sidebar);
