import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    [theme.breakpoints.down('sm')]: {
      height: "auto",
    },
  },
  brandingPanel: {
    backgroundColor: "#05354b",
    color: "#fff",
    height: "100%",
    width: "50%",
    padding: "90px 0 90px 112px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    position: "relative",
    [theme.breakpoints.down('md')]: {
      padding: "90px 0 90px 70px",
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: "center",
      height: "200px",
      width: "100%",
      padding: "30px 20px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "100px",
    },
  },
  logotype: {
    position: "absolute",
    top: "90px",
    left: "112px",
    [theme.breakpoints.down('md')]: {
      left: "70px",
    },
    [theme.breakpoints.down('sm')]: {
      position: "static",
    },
    "& img": {
      width: "137px",
      [theme.breakpoints.down('xs')]: {
        width: "115px",
      },
    }
  },
  salutation: {
    width: "100%",
    fontFamily: "Domaine Text Black, sans-serif",
    fontSize: "64px",
    lineHeight: "1.25",
    textAlign: "left",
    marginTop: "-6px",
    maxWidth: "600px",
    [theme.breakpoints.down('md')]: {
      fontSize: "56px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "40px",
      textAlign: "center",
      marginTop: "0",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
    },
  },
  loginPanel: {
    height: "100%",
    width: "50%",
    display: "flex",
    background: "#f9f9f9",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    padding: "74px 90px",
    [theme.breakpoints.down('sm')]: {
      display: "block",
      height: "auto",
      minHeight: "calc(100vh - 200px)",
      width: "100%",
      padding: "30px 20px 130px 20px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "20px 20px 130px 20px",
      minHeight: "calc(100vh - 100px)",
    },
  },
  form: {
    width: "100%",
    "& #login-form": {
      width: "100%",
      alignItems: "center",
    },
    "& .formRow": {
      position: "relative",
      width: "100%",
      padding: "10px 0",
      "& label": {
        fontWeight: "bold",
        color: "#000",
        fontSize: "17px",
      },
    },
    "& .errorMessage": {
      padding: "5px",
      marginBottom: "10px",
      textAlign: "center",
      color: "#FF0000",
    }
  },
  loginHeading: {
    fontWeight: "500",
    textAlign: "left",
    fontFamily: "Domaine Text Black, sans-serif",
    fontSize: "36px",
    color: "#111",
    [theme.breakpoints.down('sm')]: {
      fontSize: "26px",
    },
  },
  loginField: {
    border: "1px solid #e6e6e6",
    borderRadius: "4px",
    padding: "7px 15px",
    fontSize: "17px",
    margin: "10px 0 5px 0",
    "& input": {
      fontSize: "17px",
    }
  },
  passwordField: {
    border: "1px solid #e6e6e6",
    borderRadius: "4px",
    padding: "7px 15px",
    fontSize: "17px",
    margin: "10px 0 0 0",
  },
  inputIcon: {
    color: "#b4c2c9",
    position: "relative",
    right: "-10px",
  },
  forgotButton: {
    position: "absolute",
    top: "0",
    right: "0",
    paddingLeft: "0",
    paddingRight: "0",
    fontSize: "16px",
  },
  signinButton: {
    width: "100%",
    maxWidth: "none",
  },
  formFooter: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    textAlign: "center",
    color: "#000",
    padding: "20px",
    "& a": {
      color: "#fa4616",
      textDecoration: "none",
    }
  },
  policies: {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "20px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  },
  copyright: {
    fontSize: "12px",
    lineHeight: "1.5",
    color: "#666",
  },
}));
