import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Box } from '@material-ui/core';

// styles
import useStyles from './styles';

// components
import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';

// context
import { useLayoutState } from '@/context/LayoutContext';
import { useUserState } from "@/context/UserContext";

function Layout({ children, history }) {
  const classes = useStyles();
  const session = useUserState();
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={history} />
        {
          session?.isAdmin && (<Sidebar />)
        }
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
            [classes.withSidebar]: session.isAdmin,
          })}
        >
          <div className={classes.fakeToolbar} />
          <div className={"contentContainer"}>
            {children}
          </div>
          <Box
            mt={5}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent='space-between'
          />
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
