import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';

// components
import GetCurrentSessionLoader from '@/components/Loaders/GetCurrentSessionLoader';

// pages
import Error from '@/pages/error';

// context
import { userActions, useUserDispatch, useUserState } from '@/context/UserContext';
import { offerActions, useOfferDispatch } from './context/OffersContext';

// services
import { authService } from '@/services/auth';
import { marketplaceService } from '@/services/marketplace';

// Router routes
import Routes from '@/router/index';
import Login from './pages/login/Login';

export default function App() {
  const [loadingSessionUser, setLoadingSessionUser] = useState(true);
  const userDispatch = useUserDispatch();
  const offersDispatch = useOfferDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated } = useUserState();

  /**
   * We load user session if exist or navigate to '/login'.
   * When a user exist we route depending on path.
   * Existing session and incoming paths like '/' or '/login' we redirect to '/app'.
   * For admin we init other states such as get all accounts.
   */
  const initApp = async () => {
    const session = await authService.getSessionUser();

    if (!session) {
      localStorage.clear();
      const next = encodeURIComponent(`${location.pathname}${location.search}`);
      history.push(`/login${location.search && `?next=${next}`}`);
      setLoadingSessionUser(false);
      return;
    }

    const { user } = session;
    const isAdmin = user.role === 'admin';

    userDispatch(userActions.loginSuccess({ user }));

    const { pathname, search } = location;
    const exactPathIsLoginOrRoot = pathname === '/login' || pathname === '/';
    const path = exactPathIsLoginOrRoot ? '/app' : location.pathname;

    if (isAdmin) {
      await marketplaceService.GetAllAccounts();
    }

    // await offersDispatch(offerActions.fetchBrandPaymentOffersConfigs(user.email));
    // await offersDispatch(offerActions.fetchOffers());

    history.push(`${path}${search}`);
    setLoadingSessionUser(false);
  }

  useEffect(() => {
    initApp();
  }, []);

  // We show a loader if loading user session.
  // When done loading session we render list of Routes.
  return loadingSessionUser ? (
    <GetCurrentSessionLoader />
  ) : (
    <Router>
      <Switch>
        <Route exact path="/">
          {isAuthenticated ? <Redirect to="/app" /> : <Login />}
        </Route>
        {Routes.map((route) => (
          <Route
            path={route.path}
            component={route.component}
            key={route.name}
            exact={route.metadata.exact}
          />
        ))}
        <Route component={Error} />
      </Switch>
    </Router>
  );
}
