import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  adminContainer: {
    flexDirection: "column",
  },
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  selectBrand: {
     minWidth: "470px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "0",
    },
  },
  buttonsContainer: {
  },
  notificationCallButton: {
    marginRight: "10px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  tableContainer: {
    maxWidth: "975px",
    margin: "0 auto",
    overflow: "visible",
    "& table": {
      tableLayout: "fixed",
    },
    "& thead th": {
      width: "25%",
      fontSize: "15px",
      padding: "24px 16px",
      [theme.breakpoints.down('md')]: {
        fontSize: "13px",
        padding: "20px 16px",
      },
      "& img": {
        height: "25px",
        width: "auto",
        position: "relative",
        top: "2px",
        [theme.breakpoints.down('md')]: {
          height: "23px",
        },
      }
    },
    "& tbody > tr > td": {
      borderBottom: "3px solid #ededed",
      fontSize: "15px",
      padding: "24px 16px",
      wordWrap: "break-word",
      [theme.breakpoints.down('md')]: {
        fontSize: "13px",
        padding: "20px 16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    "& tbody > tr:last-child > td": {
      borderBottom: "0",
    },
  },
  offersListing: {
    padding: "0 !important",
    "& table": {
      marginBottom: "-1px",
      tableLayout: "fixed",
      "& td": {
        width: "33.3333%",
        fontSize: "15px",
        padding: "24px 16px",
        [theme.breakpoints.down('md')]: {
          fontSize: "13px",
          padding: "20px 16px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      "& tr:last-child td": {
        borderBottom: "0",
      }
    },
    "& .no-offers": {
      color: "#ccc",
    }
  },
  gatewayStatus: {
    "& span":{
      display: "inline-block",
      position: "relative",
      paddingLeft: "20px",
      "&::before": {
        content: "'\\2022'",
        fontSize: "45px",
        lineHeight: "0.5",
        position: "absolute",
        top: "-2px",
        left: "0",
      },
      "&.active::before":{
        color: "#40c265"
      },
      "&.error::before":{
        color: "#f03d27"
      },
      "&.warning::before":{
        color: "#f8aa15"
      }
    }
  },
  formControl: {
    minWidth: 120,
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8'
    }
  },
  gatewayList : {
    textAlign: "left",
    textTransform: "capitalize",
  }
}));
