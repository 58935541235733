import Login from '@/pages/login/Login';
import loadable from '@loadable/component';

const PublicRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    metadata: { isPrivate: false },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: loadable(() => import('@/pages/forgot-password/ForgotPassword')),
    metadata: { isPrivate: false },
  },
];

export default PublicRoutes;
